import React, { useEffect, useState } from "react";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import useGetProjectsManager from "../controllers/getProjectsController";
import ProjectsModal from "../components/ProjectsModal";
import useDebounce from "../../../utils/UseDebounce";
import SearchField from "../../../generalComponents/SearchField";
import { DeleteProjectManager } from "../controllers/deleteProjectController";
import AdminDeleteConfirmationModal from "../../../generalComponents/AdminDeleteConfirmationModal";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import { hasPermissions } from "../../../constants/permissions";

const Projects = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const navigate = useNavigate();

  const { data, isLoading } = useGetProjectsManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = ["S/N", "Name", "Warehouse", "Location", "Items", "Action"];

  const getFormattedValue = (el, index) => {
    return [index + 1, el?.name, el?.warehouse, el?.location, el?.stock_count];
  };

  const options = [
    { label: "View Project", permissions: ["view_project"] },
    { label: "Edit Project", permissions: ["change_project"] },
    { label: "Delete Project", permissions: ["delete_project"] },
  ];

  const {
    deleteProject,
    isLoading: deleting,
    isSuccess: deleted,
  } = DeleteProjectManager({
    id: selected,
  });

  const handleDelete = async () => {
    await deleteProject();
  };

  useEffect(() => {
    if (deleted) {
      document.getElementById("admin_delete").close();
    }
  }, [deleted]);

  return (
    <BaseDashboardNavigation>
      <div>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
          <div className="flex items-center mb-2 md:mb-0">
            <h3 className="text-[24px] font-bold">Projects Management</h3>
          </div>
        </div>
        <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="">
              <SearchField
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />{" "}
            </div>
            <div
              className={`${
                hasPermissions(["add_project"])
                  ? "flex space-x-[10px] mt-3 md:mt-0"
                  : "hidden"
              } `}
            >
              {" "}
              <ButtonWithIcon
                buttonText={"Add Project"}
                radius={"md"}
                icon={BiPlus}
                onClick={() => {
                  document.getElementById("create_project").showModal();
                }}
              />
            </div>
          </div>
          <div className="h-[67vh] w-full relative">
            {
              <TablesComponent
                isLoading={isLoading}
                data={data?.data?.results}
                getFormattedValue={getFormattedValue}
                headers={headers}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                toggleRowFunction={() => {}}
                toggleSelectAllFunction={() => {}}
                options={options}
                popUpFunction={(option, index, val) => {
                  console.log(option, index, val);
                  setSelected(val);
                  if (index === 0) {
                    navigate(`/projects/${val.name}`);
                  }
                  if (index === 1) {
                    document.getElementById("create_project").showModal();
                  }
                  if (index === 2) {
                    setSelected(val.id);
                    document.getElementById("admin_delete").showModal();
                  }
                }}
                showCheckBox={false}
              />
            }
          </div>
          <div className="flex items-center justify-between mt-4">
            <p className="text-14px text-brandBlack">
              {" "}
              {startItem} - {endItem} of {data?.data?.pagination?.count}
            </p>
            <PaginationRounded
              defaultPage={data?.data?.pagination?.page}
              count={data?.data?.pagination?.total_pages}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        </div>
      </div>
      <AdminDeleteConfirmationModal
        body={`Are you sure you want to delete this project?`}
        title={"Delete Project"}
        buttonText={"Yes, Delete"}
        isLoading={deleting}
        onClick={handleDelete}
      />
      <ProjectsModal details={selected} />
    </BaseDashboardNavigation>
  );
};

export default Projects;
