import React from "react";
import {
  auctionFunctionality,
  exchangeManagement,
  inventoryVisibility,
  seamlessBorrowing,
  seamlessDisposal,
} from "../../../assets/icons";

const KeyFeatures = () => {
  const list = [
    {
      title: "Inventory Visibility",
      description:
        "e-MMS empowers you to gain unparalleled insights into your inventory landscape while providing real-time visibility and tracking of every item and optimizing business intelligence.",
      color: "bg-[#1F253F]", // Card color
      icon: <img src={inventoryVisibility} alt="Inventory Visibility" />, // Icon inside circle
    },
    {
      title: "Exchange Management",
      description:
        "e-MMS facilitates a streamlined and smooth process of exchanging items among operators within the sector, promoting collaboration and resource optimization among industry players.",
      color: "bg-[#1D3D3A]", // Card color
      icon: <img src={exchangeManagement} alt="Exchange Management" />, // Icon inside circle
    },
    {
      title: "Seamless Borrowing",
      description:
        "e-MMS simplifies the borrowing process, allowing operators to seamlessly share resources to address pressing operational needs. This enhances cooperation and reduces downtime by efficiently utilizing surplus resources within the network.",
      color: "bg-[#A22C29]", // Card color
      icon: <img src={seamlessBorrowing} alt="Seamless Borrowing" />, // Icon inside circle
    },
    {
      title: "Seamless Item Disposal",
      description:
        "e-MMS provides a user-friendly platform to effortlessly facilitate the disposal of items that are no longer fit for operational purposes, ensuring optimal space utilization and environmental considerations.",
      color: "bg-[#DD6031]", // Card color
      icon: <img src={seamlessDisposal} alt="Seamless Disposal" />, // Icon inside circle
    },
    {
      title: "Auction Functionality",
      description:
        "Optimize the value of scrap assets through our integrated auction feature. e-MMS facilitates a transparent and efficient auction, connecting bidders for fair and competitive auction experience.",
      color: "bg-[#B1740F]", // Card color
      icon: <img src={auctionFunctionality} alt="Auction Functionality" />, // Icon inside circle
    },
  ];

  return (
    <div className="my-10 py-16 w-full flex items-center justify-center">
      <div className="max-w-[1200px] w-full px-4">
        {/* Section Header */}
        <p className="font-clash font-medium text-[14px] text-center text-brandGreen mb-2">
          KEY FEATURES
        </p>
        <h3 className="font-clash font-medium text-[48px] text-center text-blackColor leading-[1.2] mb-8">
          Your Dedicated Partner in <br /> Material Management
        </h3>
        {/* First row with 3 cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {list.slice(0, 3).map((item, index) => (
            <div
              key={index}
              className={`flex flex-col p-6 rounded-lg ${item.color} text-white`}
            >
              <div className="w-12 h-12 flex items-center justify-center rounded-full text-black mb-4">
                {item.icon}
              </div>
              <h3 className="font-clash font-medium text-[28px]">
                {item.title}
              </h3>
              <p className="font-instrument font-regular text-16px mt-2">
                {item.description}
              </p>
            </div>
          ))}
        </div>
        {/* Second row with 2 cards, centered */}
        <div className="flex justify-center gap-8 mt-8">
          {list.slice(3).map((item, index) => (
            <div
              key={index}
              className={`flex flex-col p-6 rounded-lg ${item.color} text-white w-[350px]`}
            >
              <div className="w-12 h-12 flex items-center justify-center rounded-full text-black mb-4">
                {item.icon}
              </div>
              <h3 className="font-clash font-medium text-[28px]">
                {item.title}
              </h3>
              <p className="font-instrument font-regular text-16px mt-2">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KeyFeatures;
