import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetMarketplaceItemsManager = ({
  page,
  pageSize = "15",
  searchQuery = "",
}) => {
  return useQuery(["stocks", page, pageSize, searchQuery], async () => {
    try {
      const response = await AxiosWithToken.get(
        `/stock/exchange/market/?page=${page}&size=${pageSize}${searchQuery}`
      );
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetMarketplaceItemsManager;
