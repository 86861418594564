import React, { useState } from "react";

import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";

import { useNavigate } from "react-router-dom";
import useGetBorrowRequestsManager from "../controllers/getBorrowRequestsController";
import StatusButton from "../../../generalComponents/StatusButton";
import { RejectBorrowManager } from "../controllers/rejectBorrowController";
import useDebounce from "../../../utils/UseDebounce";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import SearchField from "../../../generalComponents/SearchField";
import ReturnModal from "../../returns/components/ReturnModal";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import { hasPermissions } from "../../../constants/permissions";
import { BiUndo } from "react-icons/bi";
import { CgEye } from "react-icons/cg";

const BorrowedItems = ({ details }) => {
  const [selected, setSelected] = useState("");
  const [assetName, setAssetName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const navigate = useNavigate();

  const { data, isLoading } = useGetBorrowRequestsManager({
    is_borrower_client: true,
    status: "completed",
    is_returned: true,
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const { rejectBorrow } = RejectBorrowManager({ id: selected });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "S/N",
    "Item Name",
    "Quantity",
    "Request Date",
    "Borrow Status",
    "Return Status",
    "Action",
  ];

  // const options = [
  //   {
  //     label: "View Asset",
  //     permissions: ["view_stockborrow"],
  //   },
  //   { label: "Return Asset", permissions: ["add_stockreturn"] },
  // ];

  const getFormattedValue = (el, index) => {
    return [
      index + 1,
      el?.stock,
      el?.quantity,
      <span>{new Date(el?.created_datetime).toLocaleDateString()}</span>,
      <span>
        <StatusButton status={el?.status} />
      </span>,
      <span>
        <StatusButton status={el?.return_status} />
      </span>,
      <div className="flex gap-2">
        <ButtonWithIcon
          buttonText="View"
          icon={CgEye}
          buttonColor="transparent"
          textColor="text-brandGreen"
          onClick={() => {
            navigate(`/borrow/request/${el?.id}`);
          }}
          className="text-[8px] border border-brandGreen text-brandGreen my-2"
        />
        {el?.return_status === "PENDING" && (
          <div
            className={`${
              hasPermissions(["add_stockreturn"])
                ? "flex space-x-[10px] mt-3 md:mt-0"
                : "hidden"
            } `}
          >
            <ButtonWithIcon
              buttonText="Return"
              icon={BiUndo}
              buttonColor="transparent"
              textColor="text-brandGreen"
              onClick={() => {
                setSelected(el?.id);
                setAssetName(el?.stock);
                document.getElementById("return_modal").showModal();
              }}
              className="text-[8px] border border-brandGreen text-brandGreen my-2"
            />
          </div>
        )}
      </div>,
    ];
  };

  const handleRejection = (id) => {
    rejectBorrow({ id });
  };

  return (
    <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center gap-3">
          <SearchField
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />{" "}
        </div>
      </div>
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            toggleRowFunction={() => {}}
            toggleSelectAllFunction={() => {}}
            popUpFunction={(option, index, val) => {
              console.log(option, index, val);
              if (index === 0) {
                navigate(`/borrow/request/${val.id}`, {
                  state: { fromIncomingRequests: true },
                });
              }
              if (index === 1) {
                setSelected(val.id);
                document.getElementById("return_modal").showModal();
              }
              if (index === 2) {
                setSelected(val.id);
                handleRejection(val.id);
              }
            }}
            hideActionButton
            // options={options}
            showCheckBox={false}
            // Close popup function
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {" "}
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
      <ReturnModal id={selected} assetName={assetName} />
    </div>
  );
};
export default BorrowedItems;
