import React from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";

const RemitaLoadingModal = () => {
  return (
    <ModalManagement
      id="loading_modal"
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <div className="md:w-[300px] h-auto rounded-[6px] relative bg-white flex flex-col items-center justify-center p-6">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-brandGreen mb-4"></div>
        <p className="text-lg font-medium text-center">
          Generating payment details...
        </p>
        <p className="text-sm text-gray-600 text-center mt-2">
          Please wait while we set up your payment
        </p>
      </div>
    </ModalManagement>
  );
};

export default RemitaLoadingModal;
