import React, { useEffect, useState } from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import SelectWithFullBorder from "../../../generalComponents/SelectWithFullBorder";
import useGetWarehouseManager from "../../warehouse/controllers/getWarehouseController";
import { CreateProjectManager } from "../controllers/createProjectController";
import { UpdateProjectsManager } from "../controllers/updateProjectController";

const ProjectsModal = ({ details }) => {
  const [currentPage] = useState(1);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);

  const { data: warehouses } = useGetWarehouseManager({
    enabled: true,
    page: currentPage,
  });

  const { createProject, isLoading, isSuccess } = CreateProjectManager();
  const {
    updateProject,
    isLoading: updating,
    isSuccess: updated,
  } = UpdateProjectsManager({ id: details?.id });

  useEffect(() => {
    if (details) {
      setName(details?.name);
      setSelectedWarehouse(details?.warehouse);
      setLocation(details?.location);
    }
  }, [details]);

  const handleCreateProject = async () => {
    const ProjectData = {
      name,
      warehouse: selectedWarehouse,
      location,
    };
    if (details) {
      updateProject(ProjectData);
    } else {
      createProject(ProjectData);
    }
  };

  const clearFields = () => {
    setName("");
    setLocation("");
    setSelectedWarehouse("");
  };

  useEffect(() => {
    if (isSuccess || updated) {
      document.getElementById("create_project").close();
    }
  }, [isSuccess, updated]);

  return (
    <div>
      <ModalManagement
        id={"create_project"}
        title={details ? "Update Project" : "Create Project"}
        onClose={clearFields}
      >
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Project Name"}
                placeholder={"Enter Project Name"}
                type={"text"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Location"}
                placeholder={"Enter Location"}
                type={"text"}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <div className="flex-1">
                <SelectWithFullBorder
                  selectOptions={[
                    ...(warehouses?.data?.results?.map((warehouse) => ({
                      label: warehouse?.name,
                      value: warehouse?.id,
                    })) || []),
                  ]}
                  value={selectedWarehouse}
                  onChange={(e) => setSelectedWarehouse(e.target.value)}
                  placeholder="Select Warehouse"
                  label="Warehouse"
                  required
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
              onClick={() => {
                document.getElementById("create_project").close();
                clearFields();
              }}
            />
            <CustomButton
              buttonText={details ? "Update Project" : "Create Project"}
              onClick={handleCreateProject}
              isLoading={isLoading || updating}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default ProjectsModal;
