import React, { useEffect } from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import CustomButton from "../../../generalComponents/Button";
import { formatNumber } from "../../../utils/formatNumber";
import useGetTransactionById from "../controllers/getTransactionByIdController";
import Loader from "../../../generalComponents/Loader";
import StatusButton from "../../../generalComponents/StatusButton";
import { formatDateAndTime } from "../../../utils/formatDateAndTime";
import { noImage, pdfIcon } from "../../../assets/images";
import { AcceptTransactionsManager } from "../controllers/acceptTransactionsController";
import { RejectTransactionsManager } from "../controllers/rejectTransactionsController";

const TransactionConfirmationModal = ({ transaction }) => {
  const { data, isLoading } = useGetTransactionById(transaction);

  const transactionDetails = data?.data;

  const { acceptTransaction, isSuccess: approved } =
    AcceptTransactionsManager(transaction);

  const { rejectTransaction } = RejectTransactionsManager(transaction);

  const handleApproval = () => {
    acceptTransaction({ id: transaction });
  };

  const handleRejection = () => {
    rejectTransaction({ id: transaction });
  };

  useEffect(() => {
    if (approved) {
      document.getElementById("transaction_confirmation").close();
    }
  }, [approved]);

  return (
    <ModalManagement
      id={"transaction_confirmation"}
      title={`Reference: ${transactionDetails?.reference}`}
    >
      <div className=" m-4">
        {isLoading && <Loader />}
        {/* <h3 className="font-semibold mb-2">Make payment to this account:</h3> */}
        <div className="flex justify-between py-4">
          <p>Vendor Name:</p>
          <p className="font-medium">{transactionDetails?.client?.name}</p>
        </div>
        <div className="border-t border-[0.5px] border-gray-200 justify-center" />
        {transactionDetails?.gateway === "REMITA" ? (
          <div className="flex justify-between py-4">
            <p>Reference Code (RRR):</p>
            <p className="font-medium">
              {transactionDetails?.gateway_reference}
            </p>
          </div>
        ) : (
          <div className="flex justify-between py-4">
            <p>Depositor's Name</p>
            <p className="font-medium">{transactionDetails?.sender_name}</p>
          </div>
        )}

        <div className="border-t border-[0.5px] border-gray-200 justify-center" />
        <div className="flex justify-between py-4">
          <p>Amount Paid:</p>
          <p className="font-medium">
            ₦{formatNumber(transactionDetails?.amount)}
          </p>
        </div>
        <div className="border-t border-[0.5px] border-gray-200 justify-center" />
        <div className="flex justify-between py-4">
          <p>Payment Date:</p>
          {transactionDetails?.payment_date ? (
            <p className="font-semibold">
              {formatDateAndTime(transactionDetails?.payment_date)}
            </p>
          ) : (
            "Pending"
          )}
        </div>
        <div className="border-t border-[0.5px] border-gray-200 justify-center" />
        <div className="flex justify-between pt-4">
          <p>Payment Status:</p>
          <p className="font-semibold">
            <StatusButton status={transactionDetails?.status} />
          </p>
        </div>

        <div
          className={`w-auto h-[[auto]] rounded-[6px] relative bg-white flex flex-col pt-6`}
        >
          <div className="flex flex-col items-center justify-center h-auto">
            {transactionDetails?.gateway !== "REMITA" && (
              <div className="w-[473px] h-[220px] text-center border border-dashed border-gray-400 mb-4 rounded-md flex flex-col items-center justify-center">
                {transactionDetails?.receipt_url ? (
                  <img
                    src={transactionDetails?.receipt_url || pdfIcon}
                    alt="Preview"
                    className="w-52 h-52 object-cover cursor-pointer"
                    onClick={() =>
                      window.open(transactionDetails.receipt_url, "_blank")
                    }
                  />
                ) : (
                  <img
                    src={noImage}
                    alt="Preview"
                    className="w-52 h-52 object-cover"
                  />
                )}
              </div>
            )}

            <div className="mt-2"></div>
          </div>
        </div>
        {transactionDetails?.status !== "CONFIRMED" &&
          transactionDetails?.gateway !== "REMITA" && (
            <div className="flex justify-center space-x-2 mt-4 w-full">
              <CustomButton
                buttonText={"Reject Receipt"}
                textColor={"#ffffee"}
                className="bg-white border border-red-500 text-red-500"
                onClick={handleRejection}
              />
              <CustomButton
                buttonText={"Confirm Receipt"}
                onClick={handleApproval}
              />
            </div>
          )}
      </div>
      {/* </div> */}
    </ModalManagement>
  );
};

export default TransactionConfirmationModal;
