import { formatNumber } from "../../../utils/formatNumber";
import Loader from "../../../generalComponents/Loader";
import useGetExchangeOverviewManager from "../controller/GetExchangeOverviewController";

const ExchangeOverview = ({ startDate, endDate, operatorId }) => {
  const { data: exchangeOverview, isLoading } = useGetExchangeOverviewManager({
    client: operatorId,
    start_date: startDate,
    end_date: endDate,
  });

  return (
    <div className="flex flex-col items-end justify-normal w-full relative">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-4 gap-6 mb-8 w-full">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Exchange Items:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(exchangeOverview?.total_exchange_items_count) || 0}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Incoming Exchange:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(exchangeOverview?.total_outgoing_count) || 0}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Outgoing Exchange:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(exchangeOverview?.total_incoming_count) || 0}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExchangeOverview;
