import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetDisposalTemplateManager = (endpoint) => {
  return useQuery(["stocks"], async () => {
    try {
      const response = await AxiosWithToken.get(
        "/stock/uploads/disposal/template/",
        {
          responseType: "blob", // This ensures the response is treated as a binary Blob
        }
      );
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetDisposalTemplateManager;
