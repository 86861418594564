import React, { useEffect, useState } from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import { CreateWarehouseManager } from "../controllers/createWarehouseController";
import { UpdateWarehouseManager } from "../controllers/updateWarehouseController";

const WarehouseModal = ({ details }) => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");

  const { createWarehouse, isLoading, isSuccess } = CreateWarehouseManager();

  const { updateWarehouse, isSuccess: updated } = UpdateWarehouseManager({
    id: details?.id,
  });

  useEffect(() => {
    if (details) {
      setName(details?.name);
      setLocation(details?.location);
    }
  }, [details]);

  const handleCreateWarehouse = async () => {
    const warehouseData = {
      name,
      location,
    };
    if (details) {
      updateWarehouse(warehouseData);
    } else {
      createWarehouse(warehouseData);
    }
  };

  const clearFields = () => {
    setName("");
    setLocation("");
  };
  useEffect(() => {
    if (isSuccess || updated) {
      document.getElementById("create_warehouse").close();
    }
  }, [isSuccess, updated]);

  return (
    <div>
      <ModalManagement
        id={"create_warehouse"}
        title={details ? "Update Warehouse" : "Create Warehouse"}
        onClose={clearFields}
      >
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Warehouse Name"}
                placeholder={"Enter Warehouse Name"}
                type={"text"}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Location"}
                placeholder={"Enter Location"}
                type={"text"}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
              onClick={() => {
                document.getElementById("create_warehouse").close();
                clearFields();
              }}
            />
            <CustomButton
              buttonText={details ? "Update Warehouse" : "Create Warehouse"}
              onClick={handleCreateWarehouse}
              isLoading={isLoading}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default WarehouseModal;
