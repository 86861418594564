import React, { useState } from "react";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import useGetReturnItemsManager from "../controllers/getReturnItemsController";
import usePatchReturnRejectionManager from "../controllers/patchReturnRejectionController";
import usePatchReturnApprovalManager from "../controllers/patchReturnApprovalController";
import usePatchCompleteReturnManager from "../controllers/patchCompleteReturnController";
import ScheduleModal from "../../Requests/components/ScheduleModal";
import useDebounce from "../../../utils/UseDebounce";
import SearchField from "../../../generalComponents/SearchField";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import { hasPermissions } from "../../../constants/permissions";
import { BiCheck } from "react-icons/bi";

const IncomingReturns = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [stockId, setStockId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&asset_name=${searchValue}`, 1000);

  const { data, isLoading } = useGetReturnItemsManager({
    is_lender_client: true,
    enabled: true,
    page: currentPage,
    searchQuery: debouncedSearchValue,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const { approvalCaller } = usePatchReturnApprovalManager({ id: stockId });
  const { rejectionCaller } = usePatchReturnRejectionManager({ id: stockId });
  const { completeCaller } = usePatchCompleteReturnManager({ id: stockId });

  const headers = ["S/N", "Asset Name", "Quantity", "Request Date", "Action"];

  // const options = [
  //   {
  //     label: "Accept Request",
  //     permissions: ["approve_stockreturn"],
  //   },
  //   {
  //     label: "Reject Request",
  //     permissions: ["reject_stockreturn"],
  //   },
  //   { label: "Schedule Inspection", permissions: ["add_inspection"] },
  //   { label: "Complete Return", permissions: ["complete_stockreturn"] },
  // ];

  const getFormattedValue = (el, index) => {
    return [
      index + 1,
      el?.stock,
      el?.quantity,
      <span>{new Date(el?.created_datetime).toLocaleDateString()}</span>,
      <div className="flex gap-2">
        {!el?.approved_datetime && (
          <div
            className={`${
              hasPermissions(["approve_stockreturn", "reject_stockreturn"])
                ? "flex space-x-[10px] mt-3 md:mt-0"
                : "hidden"
            } `}
          >
            <ButtonWithIcon
              buttonText="Accept"
              icon={BiCheck}
              buttonColor="transparent"
              textColor="text-brandGreen"
              onClick={() => {
                setTimeout(() => handleApproval(el?.id), 0);
              }}
              className="text-[8px] border border-brandGreen text-brandGreen my-2"
            />
            <ButtonWithIcon
              buttonText="Reject"
              icon={BiCheck}
              buttonColor="transparent"
              textColor="text-red-500"
              onClick={() => {
                setTimeout(() => handleRejection(el?.id), 0);
              }}
              className="text-[8px] border border-red-500 text-red-500 my-2"
            />
          </div>
        )}
        {el?.approved_datetime && !el?.completed_datetime && (
          <div
            className={`${
              hasPermissions(["complete_stockreturn"])
                ? "flex space-x-[10px] mt-3 md:mt-0"
                : "hidden"
            } `}
          >
            <ButtonWithIcon
              buttonText="Complete"
              icon={BiCheck}
              buttonColor="transparent"
              textColor="text-brandGreen"
              onClick={() => {
                setTimeout(() => handleCompleteReturn(el?.id), 0);
              }}
              className="text-[8px] border border-brandGreen text-brandGreen my-2"
            />
          </div>
        )}
      </div>,
    ];
  };

  const handleApproval = (id) => {
    approvalCaller({ id });
  };

  const handleRejection = (id) => {
    rejectionCaller({ id });
  };

  const handleCompleteReturn = (id) => {
    completeCaller({ id });
  };

  return (
    <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center gap-3">
          <SearchField
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />{" "}
        </div>
      </div>
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            // buttonFunction={(val) => {
            //   navigate(`/borrow/request/${val.id}`);
            //   console.log(val);
            // }}
            popUpFunction={(option, index, val) => {
              console.log(option, index, val);
              setStockId(val.id);
              if (index === 0) {
                handleApproval(val.id);
              }
              if (index === 1) {
                handleRejection(val.id);
              }
              if (index === 2) {
                document.getElementById("schedule_inspection").showModal();
              }
              if (index === 3) {
                handleCompleteReturn(val.id);
              }
            }}
            hideActionButton
            // options={options}
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {" "}
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
      <ScheduleModal stockId={stockId} type={"return"} />
    </div>
  );
};
export default IncomingReturns;
