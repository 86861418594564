import { formatNumber } from "../../../utils/formatNumber";
import Loader from "../../../generalComponents/Loader";
import useGetDisposalOverviewManager from "../controller/GetDisposalOverviewController";

const DisposalOverview = ({ startDate, endDate, operatorId }) => {
  const { data: disposalOverview, isLoading } = useGetDisposalOverviewManager({
    client: operatorId,
    start_date: startDate,
    end_date: endDate,
  });

  return (
    <div className="flex flex-col items-end justify-normal w-full relative">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-4 gap-6 mb-8 w-full">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Disposal Items:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(disposalOverview?.total_disposal_items_count) || 0}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Pending Items:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(
                disposalOverview?.total_pending_disposal_items_count
              ) || 0}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Approved Items:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(
                disposalOverview?.total_approved_disposal_items_count
              ) || 0}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Rejected Items:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(
                disposalOverview?.total_rejected_disposal_items_count
              ) || 0}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DisposalOverview;
