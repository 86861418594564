import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalManagement from "../../../generalComponents/ModalManagement";
import CustomButton from "../../../generalComponents/Button";
import { formatNumber } from "../../../utils/formatNumber";
import CartReceiptUploadModal from "./CartReceiptUploadModal";
import RemitaConfirmationModal from "./RemitaConfirmationModal";
import RemitaLoadingModal from "./RemitaLoadingModal";
import { PostRemitaLotsTransaction } from "../controllers/postRemitaLotsTransactionController";
import { VerifyRemitaLotTransaction } from "../controllers/verifyRemitaLotsTransactionController";
import config from "../../../config";

const CartFeeModal = ({ totalAmount, existingRRR, trxId, cartItems }) => {
  const [isLoadingScript, setIsLoadingScript] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [remitaDetails, setRemitaDetails] = useState({
    rrr: existingRRR || null,
    transactionId: trxId || null,
  });
  const [isGeneratingRRR, setIsGeneratingRRR] = useState(false);

  const lotIds = cartItems?.map((item) => item?.id);

  const { verifyLotsRemita, isLoading: verifyLoading } =
    VerifyRemitaLotTransaction();
  const {
    initiateLotRemita,
    isLoading: initiating,
    data,
  } = PostRemitaLotsTransaction();

  // Load Remita script
  useEffect(() => {
    const loadRemitaScript = () => {
      const existingScript = document.querySelector(
        'script[src*="remita-pay-inline"]'
      );
      if (existingScript) {
        document.body.removeChild(existingScript);
        setIsScriptLoaded(false);
      }

      const script = document.createElement("script");
      script.src = config.REMITA_SCRIPT_URL;
      script.async = true;

      script.onload = () => {
        setTimeout(() => {
          if (
            window.RmPaymentEngine &&
            typeof window.RmPaymentEngine.init === "function"
          ) {
            setIsScriptLoaded(true);
          } else {
            toast.error(
              "Payment system initialization failed. Please refresh the page."
            );
            setIsScriptLoaded(false);
          }
        }, 1000);
      };

      script.onerror = () => {
        toast.error("Failed to load payment system. Please try again.");
        setIsScriptLoaded(false);
      };

      document.body.appendChild(script);
      return script;
    };

    const script = loadRemitaScript();
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  // Watch for data from API response
  useEffect(() => {
    if (data && isGeneratingRRR) {
      try {
        const { gateway_reference, reference } = data.data;

        if (gateway_reference && reference) {
          setRemitaDetails({
            rrr: gateway_reference,
            transactionId: reference,
          });
          setIsGeneratingRRR(false);

          // Close loading modal
          document.getElementById("loading_modal").close();

          // Open confirmation modal
          document.getElementById("remita_confirmation_modal").showModal();
        }
      } catch (error) {
        console.error("Error extracting payment details:", error);
        toast.error("Could not process payment details. Please try again.");
        setIsGeneratingRRR(false);

        // Close loading modal
        document.getElementById("loading_modal").close();

        // Reopen the main modal
        document.getElementById("cart_fee_modal").showModal();
      }
    }
  }, [data, isGeneratingRRR]);

  const handleInitiateRemita = async () => {
    if (!isScriptLoaded) {
      toast.error("Payment system is not ready. Please refresh the page.");
      return;
    }

    // Close main payment selection modal
    document.getElementById("cart_fee_modal").close();

    // Show loading modal
    document.getElementById("loading_modal").showModal();

    setIsGeneratingRRR(true);

    try {
      // If we already have RRR and transaction ID, use them
      if (existingRRR && trxId) {
        setRemitaDetails({
          rrr: existingRRR,
          transactionId: trxId,
        });

        // Short timeout to show loading state briefly
        setTimeout(() => {
          setIsGeneratingRRR(false);
          document.getElementById("loading_modal").close();
          document.getElementById("remita_confirmation_modal").showModal();
        }, 500);
      } else {
        // Otherwise initiate a new payment
        await initiateLotRemita({ lots: lotIds });
        // The useEffect above will handle updating the state when data arrives
      }
    } catch (error) {
      toast.error(error.message || "Failed to initialize payment");
      setIsGeneratingRRR(false);

      // Close loading modal
      document.getElementById("loading_modal").close();

      // Reopen the main modal
      document.getElementById("cart_fee_modal").showModal();
    }
  };

  const handlePaymentVerification = async (rrr) => {
    try {
      await verifyLotsRemita({
        remita_reference: rrr,
        is_paid: true,
      });

      toast.success("Payment verified successfully");
      document.getElementById("payment_success_modal").showModal();
      // Close the confirmation modal
      document.getElementById("remita_confirmation_modal").close();
      document.getElementById("cart_fee_modal").close();
      setTimeout(() => {
        window.location.href = "/vendor/won";
      }, 2000);
    } catch (error) {
      toast.error("Payment verification failed. Please contact support.");
    }
  };

  const handleRemitaPayment = () => {
    if (
      !window.RmPaymentEngine ||
      typeof window.RmPaymentEngine.init !== "function"
    ) {
      toast.error("Payment system is not ready. Please refresh the page.");
      return;
    }

    // Close the confirmation modal
    document.getElementById("remita_confirmation_modal").close();

    setIsLoadingScript(true);

    try {
      const paymentEngine = window.RmPaymentEngine.init({
        key: config.REACT_APP_REMITA_PUBLIC_KEY,
        processRrr: true,
        transactionId: remitaDetails.transactionId,
        extendedData: {
          customFields: [{ name: "rrr", value: remitaDetails.rrr }],
        },
        onSuccess: async (response) => {
          await handlePaymentVerification(remitaDetails.rrr);
        },
        onError: (response) => {
          toast.error(response?.message || "Payment failed");
          // Reopen the main modal
          document.getElementById("cart_fee_modal").showModal();
        },
        onClose: () => {
          // Reopen the main modal
          document.getElementById("cart_fee_modal").showModal();
        },
      });

      paymentEngine.showPaymentWidget();
    } catch (error) {
      toast.error(error.message || "Payment initialization failed");
      // Reopen the main modal
      document.getElementById("cart_fee_modal").showModal();
    } finally {
      setIsLoadingScript(false);
    }
  };

  return (
    <div>
      {/* Main payment selection modal */}
      <ModalManagement id="cart_fee_modal">
        <div className="md:w-[500px] h-auto rounded-[6px] relative bg-white flex flex-col pt-2">
          <p className="text-20px font-medium text-center p-6">
            Select your payment method to complete the payment of ₦
            {formatNumber(totalAmount)}.
          </p>
          <div className="flex justify-center space-x-2 mt-6 w-full px-6">
            <CustomButton
              buttonText="Bank Transfer"
              textColor="#368618"
              className="bg-white border border-brandGreen text-[#368618]"
              onClick={() => {
                document.getElementById("receipt_upload").showModal();
                document.getElementById("cart_fee_modal").close();
              }}
            />
            <CustomButton
              buttonText={
                initiating || isGeneratingRRR
                  ? "Processing..."
                  : "Pay Via Remita"
              }
              onClick={handleInitiateRemita}
              disabled={initiating || isGeneratingRRR || !isScriptLoaded}
            />
          </div>
        </div>
      </ModalManagement>

      {/* Loading modal */}
      <RemitaLoadingModal />

      {/* Remita confirmation modal with RRR */}
      <RemitaConfirmationModal
        totalAmount={totalAmount}
        remitaRRR={remitaDetails.rrr}
        transactionId={remitaDetails.transactionId}
        onPayNow={handleRemitaPayment}
        isLoading={isLoadingScript || verifyLoading}
      />

      {/* Bank transfer modal */}
      <CartReceiptUploadModal totalAmount={totalAmount} lotIds={lotIds} />
    </div>
  );
};

export default CartFeeModal;
