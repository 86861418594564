import { formatNumber } from "../../../utils/formatNumber";
import Loader from "../../../generalComponents/Loader";
import useGetMarketOverviewManager from "../controller/GetMarketOverviewController";

const MarketOverview = ({ startDate, endDate, operatorId }) => {
  const { data: marketOverview, isLoading } = useGetMarketOverviewManager({
    client: operatorId,
    start_date: startDate,
    end_date: endDate,
  });

  return (
    <div className="flex flex-col items-end justify-normal w-full relative">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-4 gap-6 mb-8 w-full">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Market Items:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(marketOverview?.total_marketplace_items_count) || 0}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Purchased Items:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(marketOverview?.total_purchase_count) || 0}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Sold Items:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(marketOverview?.total_sales_count) || 0}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketOverview;
