import { useState, useEffect } from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import { BiPlus, BiX } from "react-icons/bi";
import usePostReturnsManager from "../controllers/postReturnsController";

const ReturnModal = ({ id, assetName }) => {
  const [quantity, setQuantity] = useState(0);

  const { postCaller, isSuccess } = usePostReturnsManager();

  const handleReturn = () => {
    const payload = {
      stock_borrow: id,
      quantity,
    };

    postCaller(payload);
    setQuantity(0);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("return_modal").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement
        id={"return_modal"}
        title={`Return ${assetName || "Asset"}`}
      >
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Enter Quantity"}
                placeholder={"Enter Quantity"}
                type={"number"}
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              icon={BiX}
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 w-auto"}
              onClick={() => {
                document.getElementById("return_modal").close();
              }}
            />
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Return Item"}
              // textColor={"#344054"}
              // className={"bg-white border border-gray-600 h-[40px] w-auto"}
              onClick={handleReturn}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default ReturnModal;
