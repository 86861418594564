import usePatchManager from "../../../constants/controller_templates/patch_controller_template";

export const CompleteExchangeManager = (id) => {
  const { patchCaller, isLoading, isSuccess, error, data } = usePatchManager(
    `/stock/exchange/${id}/complete/`,
    ["exchange"],
    false,
    true
  );
  const completeExchange = async (details) => {
    try {
      await patchCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    completeExchange,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
