import useFormDataPostManager from "../../../constants/controller_templates/post_formdata_controller_template";

export const CreateConversationManager = (conversation_pk) => {
  const { postCaller, isLoading, isSuccess, error, data } =
    useFormDataPostManager(
      `/conversations/${conversation_pk}/messages/`,
      ["conversations"],
      true
    );
  const createConversation = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    createConversation,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
