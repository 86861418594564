import { useState, useEffect } from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import { BiPlus } from "react-icons/bi";
import { PurchaseSalesManager } from "../controllers/purchaseSalesController";
import SelectWithFullBorder from "../../../generalComponents/SelectWithFullBorder";
import useGetWarehouseManager from "../../warehouse/controllers/getWarehouseController";

const PurchaseModal = ({ stockId, assetName, warehouse, quantity }) => {
  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(false);

  const { purchaseSales, isSuccess } = PurchaseSalesManager(stockId);
  const { data: wareHouses } = useGetWarehouseManager({
    enabled: true,
  });

  const handlePurchase = async () => {
    const payload = {
      price: purchaseAmount,
      quantity,
      warehouse: selectedWarehouse,
      purchase_reciept_file: file,
    };

    purchaseSales(payload);
  };

  const handleFileChange = (event) => {
    setFile(event?.target?.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    setFile(event?.dataTransfer?.files[0]);
  };

  const clearFields = () => {
    setPurchaseAmount(0);
    setSelectedWarehouse("");
    setFile(null);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("purchase_asset").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement
        id={"purchase_asset"}
        title={"Purchase Asset"}
        onClose={clearFields}
      >
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-2">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Asset Name"}
                value={assetName}
                disabled
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Quantity"}
                value={quantity}
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <SelectWithFullBorder
                selectOptions={[
                  { label: "Select Warehouse", value: "" },
                  ...(wareHouses?.data?.results?.map((wh) => ({
                    label: wh?.name,
                    value: wh?.id,
                  })) || []),
                ]}
                value={selectedWarehouse}
                onChange={(e) => setSelectedWarehouse(e.target.value)}
                placeholder="Select Warehouse"
                label="Select Warehouse"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Enter Purchase Amount"}
                placeholder={"Enter Purchase Amount"}
                type={"number"}
                value={purchaseAmount}
                onChange={(e) => setPurchaseAmount(e.target.value)}
                required
              />
            </div>
          </div>
          <div
            className={`w-auto h-[[auto]] rounded-[6px] relative bg-white flex flex-col pt-6 ${
              dragging ? "bg-gray-100" : ""
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <p className="text-[14px] pb-2">
              Upload Receipt <span className="text-red-700 text-[14px]">*</span>
            </p>
            <div className="flex flex-col items-center justify-center h-auto">
              <div className="w-[473px] h-[100px] text-center border border-dashed border-gray-400 mb-4 rounded-md flex flex-col items-center justify-center">
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  onChange={handleFileChange}
                  accept=".pdf, .png, .jpg, .jpeg"
                />
                <label
                  htmlFor="fileInput"
                  className="cursor-pointer flex flex-col items-center"
                >
                  <p className="text-[14px]">
                    <span className="font-medium text-brandGreen">
                      Click to Upload
                    </span>{" "}
                    or drag and drop{" "}
                  </p>
                  <p className="text-[12px] text-[#98A2B3]">
                    File format: .pdf, .png, .jpg, .jpeg
                  </p>
                </label>
                {file && (
                  <p className="mt-2 text-[14px]">
                    Selected file: {file?.name}
                  </p>
                )}
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <SelectWithFullBorder
                label={"Select Exchange Asset"}
                selectOptions={
                  stockItems?.data?.results?.map((stock) => ({
                    label: stock?.asset_name,
                    value: stock?.stock_id,
                  })) || []
                }
                value={selectedExchangeAsset}
                onChange={(e) => setSelectedExchangeAsset(e.target.value)}
                placeholder="Select Exchange Asset"
              />
            </div>
          </div> */}
          {/* <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Purchase Amount"}
                placeholder={"Enter Amount"}
                type={"number"}
                value={purchaseAmount}
                onChange={(e) => setPurchaseAmount(e.target.value)}
                required
              />
            </div>
          </div> */}
          {/* <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <SelectWithFullBorder
                selectOptions={[
                  { label: "Select Warehouse", value: "" },
                  ...(wareHouses?.data?.results?.map((warehouse) => ({
                    label: warehouse?.name,
                    value: warehouse?.id,
                  })) || []),
                ]}
                value={selectedWarehouse}
                onChange={(e) => setSelectedWarehouse(e.target.value)}
                placeholder="Select Warehouse"
                label="Select Warehouse"
              />
            </div>
          </div> */}
          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 h-[40px] w-auto"}
              onClick={() => {
                document.getElementById("purchase_asset").close();
                clearFields();
              }}
            />
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Purchase Asset"}
              onClick={handlePurchase}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default PurchaseModal;
