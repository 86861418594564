import React, { useEffect, useState } from "react";
import { CreateTicketManager } from "../controllers/createTicketController";
import ModalManagement from "../../../generalComponents/ModalManagement";
import CustomButton from "../../../generalComponents/Button";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import { AiOutlinePaperClip } from "react-icons/ai";

const CreateTicketModals = ({ details }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const { createTicket, isLoading, isSuccess } = CreateTicketManager();

  const handleCreateTicket = async () => {
    const ticketData = {
      subject: subject,
      body: message,
      attachment: file,
    };

    await createTicket(ticketData);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const clearFields = () => {
    setSubject("");
    setMessage("");
    setFile(null);
    setFileName("");
  };

  useEffect(() => {
    if (isSuccess) {
      clearFields();
      document.getElementById("create_ticket").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement
        id={"create_ticket"}
        title={"Create Ticket"}
        onClose={clearFields}
      >
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Subject"}
                placeholder={"Enter Subject"}
                type={"text"}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Message"}
                placeholder={"Enter Your Message"}
                type={"text"}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                isTextArea
                required
              />
            </div>
          </div>
          {/* Attachment Section */}
          <div className="flex items-center justify-end mt-2 space-x-2">
            <label
              htmlFor="file-upload"
              className="flex items-center text-brandGreen cursor-pointer"
            >
              <AiOutlinePaperClip size={20} />
              <span className="ml-1">Attach File</span>
            </label>
            <input
              type="file"
              id="file-upload"
              className="hidden"
              onChange={handleFileChange}
              accept=".png, .jpeg, .jpg, .pdf"
            />
            {fileName && (
              <span className="text-sm text-gray-500">{fileName}</span>
            )}
          </div>
          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
              onClick={() => {
                document.getElementById("create_ticket").close();
                clearFields();
              }}
            />
            <CustomButton
              buttonText={"Create Ticket"}
              onClick={handleCreateTicket}
              isLoading={isLoading}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default CreateTicketModals;
