import React, { useCallback, useEffect, useState } from "react";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import CustomButton from "../../../../generalComponents/Button";
import { successCheck, uploadIcon } from "../../../../assets/icons";
import { UploadDisposalManager } from "../controllers/UploadDisposalController";
import FileValidator from "../../../../generalComponents/FileValidator";
import UploadStatusModal from "../../../../generalComponents/UploadStatus";
import useGetDisposalTemplateManager from "../controllers/getDisposalTemplateController";

const DisposalUploadModal = () => {
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [isValidationComplete, setIsValidationComplete] = useState(false);
  const { data: disposalTemplate } = useGetDisposalTemplateManager();

  const { uploadDisposal, isSuccess, isLoading, data } =
    UploadDisposalManager();
  const [batchId, setBatchId] = useState(null);

  useEffect(() => {
    const fetchBatchId = async () => {
      if (data?.data?.batch_id) {
        setBatchId(await data.data.batch_id);
      }
    };
    fetchBatchId();
  }, [data]);

  const handleFileChange = useCallback((event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setIsValidationComplete(false);
    setValidationErrors([]);
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    setDragging(false);
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
    setIsValidationComplete(false);
    setValidationErrors([]);
  }, []);

  const handleValidationComplete = useCallback((errors) => {
    setValidationErrors(errors);
    setIsValidationComplete(true);
  }, []);

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    if (!isValidationComplete) {
      alert("Please wait for file validation to complete.");
      return;
    }

    if (validationErrors.length > 0) {
      alert("Please fix validation errors before uploading.");
      return;
    }

    try {
      await uploadDisposal(file);
      setFile(null);
      setIsValidationComplete(false);
      setValidationErrors([]);
    } catch (uploadError) {
      console.error("Upload error:", uploadError);
      alert("An error occurred while uploading the file. Please try again.");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("disposal_upload").close();
      document.getElementById("upload_status").showModal();
      setFile(null);
    }
  }, [isSuccess]);

  const isFileValid = isValidationComplete && validationErrors.length === 0;

  const clearFile = () => {
    setFile(null);
    setValidationErrors([]);
    setIsValidationComplete(false);
  };

  const handleDownload = () => {
    if (disposalTemplate) {
      const url = window.URL.createObjectURL(disposalTemplate);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "stock_disposal_template.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Download link not available");
      alert(
        "Template download is not available at the moment. Please try again later."
      );
    }
  };

  return (
    <ModalManagement
      id={"disposal_upload"}
      title={"Disposal Upload"}
      onClose={() => {
        clearFile();
      }}
    >
      <div
        className={`w-auto h-auto rounded-[6px] relative bg-white flex flex-col pt-2 ${
          dragging ? "bg-gray-100" : ""
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="flex flex-col items-center justify-center h-auto">
          {file ? (
            <div>
              <FileValidator
                file={file}
                onValidationComplete={handleValidationComplete}
                className="mt-4"
              />
              {isFileValid && (
                <div className="w-[473px] h-[264px] text-center border border-dashed border-gray-400 mb-4 rounded-md flex flex-col items-center justify-center">
                  <img
                    src={successCheck}
                    className="mb-4 w-20"
                    alt="Upload Icon"
                  />
                  <div className="my-2 text-brandGreen">
                    File validated successfully! You can proceed to upload
                  </div>

                  {file && (
                    <p className="mt-2 text-[14px]">File Name: {file.name}</p>
                  )}
                  <div className="w-[90%] text-center border-t border-[#F0F2F5] relative my-6"></div>
                </div>
              )}
            </div>
          ) : (
            <div className="w-[473px] h-[264px] text-center border border-dashed border-gray-400 mb-4 rounded-md flex flex-col items-center justify-center">
              <img src={uploadIcon} className="mb-4" alt="Upload Icon" />
              <input
                type="file"
                id="fileInput"
                className="hidden"
                onChange={handleFileChange}
                accept=".xlsx,.xls"
              />
              <label
                htmlFor="fileInput"
                className="cursor-pointer flex flex-col items-center"
              >
                <p className="text-[14px]">
                  <span className="font-medium text-brandGreen">
                    Click to Upload
                  </span>{" "}
                  or drag and drop
                </p>
                <p className="text-[12px] text-[#98A2B3]">File format: .xlsx</p>
              </label>
              {file && (
                <p className="mt-2 text-[14px]">Selected file: {file.name}</p>
              )}
              <div className="w-[90%] text-center border-t border-[#F0F2F5] relative my-6"></div>
              <div className="mt-4">
                <p className="text-[14px]">
                  <span
                    className="font-medium text-brandGreen underline cursor-pointer"
                    onClick={handleDownload}
                  >
                    Click Here to Download Sample File
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end space-x-2 mt-4 w-full">
          <CustomButton
            buttonText={validationErrors?.length > 0 ? "Go back" : "Cancel"}
            onClick={() => {
              if (validationErrors?.length > 0) {
                clearFile();
              } else {
                document.getElementById("disposal_upload").close();
                clearFile();
              }
            }}
            className="bg-transparent text-brandGreen border border-brandGreen"
            textColor={"#358619"}
          />

          <CustomButton
            buttonText={"Upload"}
            onClick={handleUpload}
            className="bg-brandGreen text-white px-4 py-2 rounded-md"
            disabled={!isFileValid || isLoading}
            isLoading={isLoading}
          />
        </div>
      </div>
      <UploadStatusModal batchId={batchId} />
    </ModalManagement>
  );
};

export default DisposalUploadModal;
