import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetOperatorStatsManager = ({ type, start_date, end_date }) => {
  return useQuery(["stocks", type, start_date, end_date], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(`/operators/${type}/charts/`),
      ];
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetOperatorStatsManager;
