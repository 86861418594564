import HomePage from "../modules/home/view/HomePage";
import InventoryPage from "../modules/inventory/InventoryPage";
import DashboardPage from "../modules/dashboard/view/DashboardPage";
import EventHome from "../modules/events/view/EventHome";
import NewListingHome from "../modules/newListing/view/NewListingHome";
import ContactUsPage from "../modules/contactUs/view/ContactUsPage";
import Login from "../modules/authentication/view/Login";
import TransactionsPage from "../modules/transactions/view/TransactionsPage";
import BorrowDetailsPage from "../modules/borrow/components/BorrowDetailsPage";
import MarketplaceDetailsPage from "../modules/marketPlace/components/MarketplaceDetailsPage";
import MessagingPage from "../modules/chat/view/MessagingPage";
import NotificationsPage from "../modules/notifications/view/NotificationsPage";
import SupportPage from "../modules/support/view/SupportPage";
import SettingsPage from "../modules/settings/view/SettingsPage";
import EventAuctionPage from "../modules/events/view/EventAuctionPage";
import AuctionPage from "../modules/auctionEvents/view/AuctionPage";
import TicketDetailsPage from "../modules/support/view/TicketDetailsPage";
import OTPPage from "../modules/authentication/view/OTPPage";
import ChangePasswordPage from "../modules/authentication/components/ChangePasswordPage";
import CreateClients from "../modules/accessControl/clients/components/CreateClients";
import CreateRole from "../modules/accessControl/roles/views/CreateRole";
import Warehouse from "../modules/warehouse/view/warehouse";
import Projects from "../modules/projects/view/projects";
import ItemsByProject from "../modules/projects/view/ItemsByProject";
import ItemsByWarehouse from "../modules/warehouse/view/ItemsByWarehouse";
import StockItemDetailsComponent from "../modules/inventory/stockItems/components/StockItemDetailsComponent";
import RequestDetailsPage from "../modules/Requests/components/RequestDetailsPage";
import RequestChatPage from "../modules/Requests/components/RequestChatPage";
import Borrow from "../modules/borrow/view/Borrow";
import AssetGroups from "../modules/accessControl/assetgroups/view/AssetGroups";
import Marketplace from "../modules/marketPlace/view/MarketPlace";
import ForgotPassword from "../modules/authentication/view/ForgotPassword";
import ResetPassword from "../modules/authentication/view/ResetPassword";
import Tasks from "../modules/tasks/view/Tasks";
import Clients from "../modules/accessControl/clients/views/Clients";
import AuditLogs from "../modules/accessControl/auditTrail/view/AuditTrailLogs";
import Inspections from "../modules/inspections/view/Inspections";
import Returns from "../modules/returns/view/Returns";
import MarketRequestDetailsPage from "../modules/marketPlace/components/MarketRequestDetailsPage";
import AccessControl from "../modules/accessControl/AccessControl";
import ItemsByUploadBatch from "../modules/inventory/uploadHistory/view/ItemsByUploadBatch";
import SalesRequestDetailsPage from "../modules/marketPlace/components/SalesRequestDetailsPage";
import ExchangeRequestDetailsPage from "../modules/marketPlace/components/ExchangeRequestDetailsPage";
import DisposalManagement from "../modules/disposal/DisposalManagement";
import DisposalItemDetailsComponent from "../modules/disposal/disposalUploads/components/DisposalItemDetailsComponent";
import DisposalItemsByUploadBatch from "../modules/disposal/disposalUploadHistory/view/DisposalItemsByUploadBatch";
import BatchDetails from "../modules/disposal/disposalBatches/view/BatchDetails";
import LotsPage from "../modules/lots/view/LotsPage";
import LotItemDetails from "../modules/lots/components/LotItemDetails";
import CreateLot from "../modules/lots/components/CreateLot";
import AuctionItems from "../modules/auctionItems/view/AuctionItems";
import CreateAuction from "../modules/auctionEvents/components/CreateAuction";
import AuctionDetails from "../modules/auctionEvents/components/AuctionDetails";
import AuctionWarRoomPage from "../modules/events/view/AuctionWarRoomPage";
import EventLots from "../modules/events/view/EventLots";
import BidPage from "../modules/events/view/BidPage";
import NotPermittedPage from "../generalComponents/NotPermittedPage";
import InventoryRouter from "../utils/inventoryRouter";
import DisposalRouter from "../utils/disposalRoute";
import Cart from "../modules/cart/view/Cart";
import VendorBids from "../modules/vendor/components/VendorBids";
import VendorTransactions from "../modules/vendor/components/VendorTransactions";
import VendorProfile from "../modules/vendor/views/VendorProfile";
import WonLots from "../modules/vendor/components/WonLots";
import AuctionItemsList from "../modules/auctionItems/components/AuctionItemsList";
import UpcomingEvents from "../modules/events/view/UpcomingEvents";
import CentrifugoTest from "./CentrifugoTest";

export const publicRoutes = [
  { path: "/", element: <HomePage /> },
  { path: "/cent", element: <CentrifugoTest /> },
  { path: "/login", element: <Login /> },
  { path: "/new-listing/", element: <NewListingHome /> },
  { path: "/contact-us/", element: <ContactUsPage /> },
  { path: "/events", element: <EventHome /> },
  { path: "/upcoming-events", element: <UpcomingEvents /> },
  { path: "/cart", element: <Cart /> },
  // { path: "/events/lots/item", element: <LotItemsDetailsPage /> },
  { path: "/events/lots", element: <LotsPage /> },
  { path: "/events/:id", element: <EventLots /> },
  { path: "/otp", element: <OTPPage /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/change-password", element: <ChangePasswordPage /> },
  {
    path: "/lots/bid/:id",
    element: <BidPage />,
    // requiredPermissions: ["add_bid", "view_bid"],
  },
];

export const vendorRoutes = [
  { path: "/vendor/profile", element: <VendorProfile /> },
  { path: "/vendor/bids", element: <VendorBids /> },
  { path: "/vendor/transactions", element: <VendorTransactions /> },
  { path: "/vendor/won", element: <WonLots /> },
  // { path: "/chat", element: <MessagingPage /> },
  // { path: "/notifications", element: <NotificationsPage /> },
  // { path: "/support", element: <SupportPage /> },
];

export const protectedRoutes = [
  // { path: "/vendor/profile", element: <VendorProfile /> },
  // { path: "/vendor/bids", element: <VendorBids /> },
  // { path: "/vendor/transactions", element: <VendorTransactions /> },
  // { path: "/vendor/won", element: <WonLots /> },
  { path: "/chat", element: <MessagingPage /> },
  { path: "/notifications", element: <NotificationsPage /> },
  { path: "/support", element: <SupportPage /> },
  { path: "/support/ticket/:id", element: <TicketDetailsPage /> },
  { path: "/settings", element: <SettingsPage /> },

  //auction
  {
    path: "/auction",
    element: <AuctionPage />,
    requiredPermissions: ["view_auction"],
  },
  {
    path: "/auction/create-auction",
    element: <CreateAuction />,
    requiredPermissions: ["add_auction", "change_auction"],
  },
  {
    path: "/auction/edit-auction/:id",
    element: <CreateAuction />,
    requiredPermissions: ["add_auction", "change_auction"],
  },
  {
    path: "/auction/:id/",
    element: <AuctionDetails />,
    requiredPermissions: ["view_auction"],
  },

  //lots
  { path: "/lots", element: <LotsPage />, requiredPermissions: ["view_lot"] },
  {
    path: "/lots/create-lot",
    element: <CreateLot />,
    requiredPermissions: ["add_lot", "change_lot"],
  },
  {
    path: "/lots/edi-lot/:id",
    element: <CreateLot />,
    requiredPermissions: ["add_lot", "change_lot"],
  },
  {
    path: "/lots/edit-lot/:id",
    element: <CreateLot />,
    requiredPermissions: ["add_lot", "change_lot"],
  },
  {
    path: "/lots/:lot",
    element: <LotItemDetails />,
    requiredPermissions: ["view_lot"],
  },

  {
    path: "/lots/bid/:id",
    element: <BidPage />,
    requiredPermissions: ["add_bid", "view_bid"],
  },
  // { path: "/lots/view/:id", element: <LotItemsDetailsPage /> },
  {
    path: "/lots/:id/war-room",
    element: <AuctionWarRoomPage />,
    requiredPermissions: ["view_lot"],
  },

  { path: "/approved-items", element: <AuctionItems /> },
  //events
  { path: "/events/auction", element: <EventAuctionPage /> },

  {
    path: "/dashboard",
    element: <DashboardPage />,
  },
  {
    path: "/inventory/:id",
    element: <InventoryPage />,
    requiredPermissions: ["view_stock", "view_stockallocation"],
  },
  {
    path: "/inventory",
    element: <InventoryRouter />,
    requiredPermissions: ["view_stock", "view_stockallocation"],
  },
  {
    path: "/warehouse",
    element: <Warehouse />,
    requiredPermissions: ["view_warehouse"],
  },
  {
    path: "/projects",
    element: <Projects />,
    requiredPermissions: ["view_project"],
  },
  {
    path: "/projects/:projectName",
    element: <ItemsByProject />,
    requiredPermissions: ["view_project"],
  },
  {
    path: "/warehouse/:warehouseName",
    element: <ItemsByWarehouse />,
    requiredPermissions: ["view_warehouse"],
  },

  {
    path: "/transactions",
    element: <TransactionsPage />,
    requiredPermissions: ["view_transaction"],
  },
  { path: "/logs/:id", element: <AuditLogs /> },

  //access control
  {
    path: "/access-control",
    element: <AccessControl />,
    requiredPermissions: ["view_staff", "view_role"],
  },
  {
    path: "/create-role/",
    element: <CreateRole />,
    requiredPermissions: ["add_role"],
  },
  {
    path: "/edit-role/:id",
    element: <CreateRole />,
    requiredPermissions: ["change_role"],
  },

  {
    path: "/clients",
    element: <Clients />,
    requiredPermissions: ["view_client"],
  },
  {
    path: "/asset-groups",
    element: <AssetGroups />,
    requiredPermissions: ["view_assetgroup"],
  },
  {
    path: "/create-client/",
    element: <CreateClients />,
    requiredPermissions: ["add_client"],
  },
  {
    path: "/edit-client/:id",
    element: <CreateClients />,
    requiredPermissions: ["change_client"],
  },

  // stocks
  {
    path: "/inventory/:id/item/:id",
    element: <StockItemDetailsComponent />,
    requiredPermissions: ["view_stock", "view_stockallocation"],
  },
  {
    path: "/inventory-item/:id",
    element: <StockItemDetailsComponent />,
    requiredPermissions: ["view_stock", "view_stockallocation"],
  },

  {
    path: "/tasks",
    element: <Tasks />,
    requiredPermissions: ["view_inspection", "view_inspectionreport"],
  },

  //diposal
  {
    path: "/disposal",
    element: <DisposalRouter />,
    requiredPermissions: ["view_stockdisposal"],
  },
  {
    path: "/disposal/:id",
    element: <DisposalManagement />,
    requiredPermissions: ["view_stockdisposal"],
  },
  {
    path: "/disposal/:id/item/:id",
    element: <DisposalItemDetailsComponent />,
    requiredPermissions: ["view_stockdisposalitem"],
  },
  {
    path: "/disposal-request/:request_id",
    element: <BatchDetails />,
    requiredPermissions: ["view_stockdisposalitem"],
  },
  {
    path: "/disposal-batch/:batch_id",
    element: <DisposalItemsByUploadBatch />,
    requiredPermissions: ["view_stockdisposal"],
  },

  //borrow

  {
    path: "/borrow",
    element: <Borrow />,
    requiredPermissions: ["view_stockborrow"],
  },

  {
    path: "/inspections",
    element: <Inspections />,
    requiredPermissions: ["view_inspection"],
  },
  {
    path: "/returns",
    element: <Returns />,
    requiredPermissions: ["view_stockreturn"],
  },

  {
    path: "/borrow/request/:id",
    element: <RequestDetailsPage />,
    requiredPermissions: ["view_stockborrow"],
  },
  {
    path: "/borrow/:id",
    element: <BorrowDetailsPage />,
    requiredPermissions: ["view_stockborrow"],
  },
  {
    path: "/requests/chat/:id",
    element: <RequestChatPage />,
    requiredPermissions: ["view_conversation"],
  },

  //market place
  {
    path: "/marketplace",
    element: <Marketplace />,
    requiredPermissions: ["view_stocksale"],
  },
  {
    path: "/marketplace/request/:id",
    element: <MarketRequestDetailsPage />,
    requiredPermissions: ["view_stocksale"],
  },
  {
    path: "/marketplace/exchange/:id",
    element: <ExchangeRequestDetailsPage />,
    requiredPermissions: ["view_stockexchange"],
  },
  {
    path: "/marketplace/sales/:id",
    element: <SalesRequestDetailsPage />,
    requiredPermissions: ["view_stocksale"],
  },
  {
    path: "/marketplace/:id",
    element: <MarketplaceDetailsPage />,
    requiredPermissions: ["view_stocksale"],
  },
  {
    path: "/upload-batch/:batch_id",
    element: <ItemsByUploadBatch />,
    requiredPermissions: ["view_stockbatch"],
  },
  {
    path: "/not-permitted",
    element: <NotPermittedPage />,
  },
  {
    path: "/auction-items",
    element: <AuctionItems />,
    requiredPermissions: ["view_auction"],
  },
  {
    path: "/auction-items/:request_id",
    element: <AuctionItemsList />,
    requiredPermissions: ["view_auction"],
  },

  // { path: "/requests/return-item-list/:id", element: <ReturnItemListPage />,  },
  // {
  //   path: "/requests/return-request-list/:id",
  //   element: <ReturnRequestListPage />,
  // },

  //requests

  // { path: "/requests", element: <RequestsPage />,  },
  // {
  //   path: "/requests/sales-request-list/:id",
  //   element: <SalesRequestListPage />,
  // },
  // {
  //   path: "/requests/purchase-request-list/:id",
  //   element: <PurchaseRequestListPage />,
  // },
  // {
  //   path: "/requests/borrow-request-list/:id",
  //   element: <BorrowRequestListPage />,
  // },

  // {
  //   path: "/inventory/stocks/warehouse/:itemsName",
  //   element: <StockWarehousePage />,
  // },

  // Projects
  // {
  //   path: "/inventory/stocks/warehouse/:itemsName",
  //   element: <StockWarehousePage />,
  // },

  // {
  //   path: "/inventory/market/materials/:itemsName",
  //   element: <MarketplaceDetailsPage />,
  // },

  // { path: "/access-control/role-configuration/:id", element: <RoleConfigurations /> },
];
