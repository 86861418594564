import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import SearchField from "../../../generalComponents/SearchField";
import { noImage } from "../../../assets/images";
import { CreateLotsManager } from "../controllers/createLotsController";
import { UpdateLotsManager } from "../controllers/updateLotsController";
import useGetLotById from "../controllers/getLotByIdController";
import useDebounce from "../../../utils/UseDebounce";
import GoBackButton from "../../../generalComponents/GoBackButton";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import truncateWithTooltip from "../../../utils/truncateText";
import PaginationRounded from "../../../generalComponents/Pagination";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import { BiChevronDown, BiX } from "react-icons/bi";
import useGetAuctionItemsManager from "../controllers/getAuctionItemsController";
import Loader from "../../../generalComponents/Loader";

const CreateLot = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // State management
  const [lotName, setLotName] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [itemSearchQuery, setItemSearchQuery] = useState("");
  const [requestSearchQuery, setRequestSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [itemsPage, setItemsPage] = useState(1);
  const [requestsPage, setRequestsPage] = useState(1);
  const [batchRequestId, setBatchRequestId] = useState("");

  // Debounced search values
  const debouncedItemSearch = useDebounce(`&search=${itemSearchQuery}`, 1000);
  const debouncedRequestSearch = useDebounce(requestSearchQuery, 1000);

  // API hooks
  const { createLot, isSuccess, isLoading: isCreating } = CreateLotsManager();
  const { updateLot, isSuccess: updated } = UpdateLotsManager({ id });
  const { data: lotDetails } = useGetLotById({ id, enabled: !!id });

  // Fetch items based on search type
  const { data: searchResults, isLoading: fetchingItems } =
    useGetAuctionItemsManager({
      searchQuery: activeAccordion === "items" ? debouncedItemSearch : "",
      request_id: activeAccordion === "requests" ? debouncedRequestSearch : "",
      enabled: !!activeAccordion,
      page: activeAccordion === "items" ? itemsPage : requestsPage,
      status: "LEVEL_THREE_APPROVED",
    });

  // Reset pages when search changes
  useEffect(() => {
    setItemsPage(1);
  }, [debouncedItemSearch]);

  useEffect(() => {
    setRequestsPage(1);
  }, [debouncedRequestSearch]);

  // Load existing lot data for editing
  useEffect(() => {
    if (lotDetails?.data) {
      setLotName(lotDetails?.data?.name);
      setImage(lotDetails?.data?.image_url);
      setImagePreview(lotDetails?.data?.image_url);

      const formattedItems = lotDetails?.data?.lot_items.map((item) => ({
        id: item?.id,
        stock: {
          image: item?.image_url,
          asset_name: item?.disposal_item,
        },
        nuims_quantity: item?.quantity,
        client: item?.client || {},
      }));

      setSelectedItems(formattedItems);
      setBatchRequestId(lotDetails?.data?.request_id);
    }
  }, [lotDetails]);

  // Image handling
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Item selection handling
  const handleSelectItem = (item) => {
    if (!selectedItems.find((selected) => selected.id === item.id)) {
      setSelectedItems([...selectedItems, item]);
    } else {
      handleRemoveItem(item.id);
    }

    // If this item was part of a batch selection, unselect the batch
    if (item.request_id === batchRequestId) {
      setBatchRequestId("");
    }
  };

  const handleRemoveItem = (itemId) => {
    setSelectedItems(selectedItems.filter((item) => item.id !== itemId));
  };

  const handleBatchSelection = () => {
    if (!requestSearchQuery) return;

    if (batchRequestId === requestSearchQuery) {
      // Unselect batch
      setBatchRequestId("");
      setSelectedItems((items) =>
        items.filter((item) => item.request_id !== requestSearchQuery)
      );
    } else {
      // Select batch
      setBatchRequestId(requestSearchQuery);
      const batchItems = searchResults?.data?.results || [];
      // Add all items from batch without duplicates
      setSelectedItems((prevItems) => {
        const newItems = batchItems.filter(
          (batchItem) => !prevItems.some((item) => item.id === batchItem.id)
        );
        return [...prevItems, ...newItems];
      });
    }
  };

  // Form submission
  const handleCreateLot = async () => {
    const lotData = {
      image: image,
      name: lotName,
      items: selectedItems.map((item) => item.id),
      request_id: batchRequestId || "",
    };

    if (id) {
      await updateLot(lotData);
    } else {
      await createLot(lotData);
    }
  };

  // Navigation after success
  useEffect(() => {
    if (updated || isSuccess) {
      navigate("/lots");
    }
  }, [updated, isSuccess, navigate]);

  const { startItem, endItem } = calculatePaginationRange(
    searchResults?.data?.pagination
  );

  const toggleAccordion = (section) => {
    setActiveAccordion(activeAccordion === section ? null : section);
  };

  const handlePageChange = (page) => {
    if (activeAccordion === "items") {
      setItemsPage(page);
    } else {
      setRequestsPage(page);
    }
  };

  return (
    <BaseDashboardNavigation title="Create Lot">
      <div className="flex justify-between items-center mb-6">
        <GoBackButton />
      </div>
      <div className="flex flex-col md:flex-row gap-4">
        {/* Left side - Search and Selection */}
        <div className="w-full md:w-2/3">
          <div className="bg-white rounded-lg shadow p-6">
            {/* Image Upload */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Upload Image
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="w-full"
              />
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="mt-2 w-32 h-32 object-cover rounded"
                />
              )}
            </div>

            {/* Lot Name */}
            <InputWithFullBoarder
              label="Lot Name"
              placeholder="Enter Lot Name"
              value={lotName}
              onChange={(e) => setLotName(e.target.value)}
              className="mb-6"
            />

            {/* Custom Accordion */}
            <div className="space-y-4">
              {/* Search by Item Accordion */}
              <div className="border rounded-lg">
                <button
                  className="w-full px-4 py-3 flex justify-between items-center"
                  onClick={() => toggleAccordion("items")}
                >
                  <span className="">Search by Item</span>
                  <BiChevronDown
                    className={`transform transition-transform ${
                      activeAccordion === "items" ? "rotate-180" : ""
                    }`}
                  />
                </button>
                {activeAccordion === "items" && (
                  <div className="p-4 border-t">
                    <SearchField
                      placeholder="Search assets by name"
                      value={itemSearchQuery}
                      onChange={(e) => setItemSearchQuery(e.target.value)}
                    />
                    <div className="mt-4 overflow-x-auto">
                      {fetchingItems ? (
                        <div className="text-center">
                          <Loader />
                        </div>
                      ) : (
                        <table className="min-w-full">
                          <thead>
                            <tr className="bg-gray-50">
                              <th className="w-8 px-2 py-3"></th>
                              <th className="w-16 px-2 py-3"></th>
                              <th className="text-left px-4 py-3 text-sm font-medium text-gray-700">
                                Asset Name
                              </th>
                              <th className="text-left px-4 py-3 text-sm font-medium text-gray-700">
                                Request ID
                              </th>
                              <th className="text-left px-4 py-3 text-sm font-medium text-gray-700">
                                Operator
                              </th>
                              <th className="text-left px-4 py-3 text-sm font-medium text-gray-700">
                                Quantity
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {searchResults?.data?.results?.map(
                              (item, index) => (
                                <tr
                                  key={item?.id}
                                  className={`hover:bg-gray-50 ${
                                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                  }`}
                                >
                                  <td className="px-2 py-3">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4"
                                      checked={selectedItems.some(
                                        (selected) => selected?.id === item?.id
                                      )}
                                      onChange={() => handleSelectItem(item)}
                                    />
                                  </td>
                                  <td className="px-2 py-3">
                                    <img
                                      src={item?.stock?.image || noImage}
                                      className="w-12 h-12 rounded-full"
                                      alt={item?.stock?.asset_name}
                                    />
                                  </td>
                                  <td className="px-4 py-3">
                                    <p className="font-medium">
                                      {truncateWithTooltip(
                                        item?.stock?.asset_name
                                      )}
                                    </p>
                                  </td>
                                  <td className="px-4 py-3 text-gray-500">
                                    {item?.request_id}
                                  </td>
                                  <td className="px-4 py-3 text-gray-500">
                                    {item?.client?.name}
                                  </td>
                                  <td className="px-4 py-3 text-gray-500">
                                    {item?.nuims_quantity}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* Search by Request ID Accordion */}
              <div className="border rounded-lg">
                <button
                  className="w-full px-4 py-3 flex justify-between items-center"
                  onClick={() => toggleAccordion("requests")}
                >
                  <span className="">Search by Request ID</span>
                  <BiChevronDown
                    className={`transform transition-transform ${
                      activeAccordion === "requests" ? "rotate-180" : ""
                    }`}
                  />
                </button>

                {activeAccordion === "requests" && (
                  <div className="p-4 border-t">
                    <div className="flex items-center justify-between mb-4">
                      <SearchField
                        placeholder="Search by request ID"
                        value={requestSearchQuery}
                        onChange={(e) => setRequestSearchQuery(e.target.value)}
                      />
                      {requestSearchQuery &&
                        searchResults?.data?.results?.length > 0 && (
                          <div className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              checked={batchRequestId === requestSearchQuery}
                              onChange={handleBatchSelection}
                              className="w-4 h-4"
                            />
                            <span className="text-sm">
                              Select All Items in Batch
                            </span>
                          </div>
                        )}
                    </div>
                    {requestSearchQuery && (
                      <div className="mt-4 overflow-x-auto">
                        {fetchingItems ? (
                          <div className="text-center">
                            <Loader />
                          </div>
                        ) : (
                          <table className="min-w-full">
                            <thead>
                              <tr className="bg-gray-50">
                                <th className="w-8 px-2 py-3"></th>
                                <th className="text-left px-4 py-3 text-sm font-medium text-gray-700">
                                  Request ID
                                </th>
                                <th className="text-left px-4 py-3 text-sm font-medium text-gray-700">
                                  Asset Name
                                </th>
                                <th className="text-left px-4 py-3 text-sm font-medium text-gray-700">
                                  Quantity
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {searchResults?.data?.results?.map(
                                (item, index) => (
                                  <tr
                                    key={item?.id}
                                    className={`hover:bg-gray-50 ${
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-gray-50"
                                    }`}
                                  >
                                    <td className="px-2 py-3">
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4"
                                        checked={selectedItems.some(
                                          (selected) =>
                                            selected?.id === item?.id
                                        )}
                                        onChange={() => handleSelectItem(item)}
                                      />
                                    </td>
                                    <td className="px-4 py-3 text-gray-500">
                                      {item.request_id}
                                    </td>
                                    <td className="px-4 py-3">
                                      <p className="text-[16px] ">
                                        {truncateWithTooltip(
                                          item?.stock?.asset_name
                                        )}
                                      </p>
                                    </td>
                                    <td className="px-4 py-3">
                                      <p className="text-[16px] ">
                                        {item?.nuims_quantity}
                                      </p>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Pagination */}
            {activeAccordion && (
              <div className="flex items-center justify-between mt-4">
                <p className="text-sm text-gray-600">
                  {startItem} - {endItem} of{" "}
                  {searchResults?.data?.pagination?.count} Items
                </p>
                <PaginationRounded
                  defaultPage={
                    activeAccordion === "items" ? itemsPage : requestsPage
                  }
                  count={searchResults?.data?.pagination?.total_pages}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>

        {/* Right side - Selected Items and Batches */}
        <div className="w-full md:w-1/3">
          <div className="bg-white rounded-lg shadow p-6 space-y-6">
            {/* Batch Section */}
            <div>
              <h4 className="text-lg font-semibold mb-4">Selected Batches</h4>
              {batchRequestId ? (
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="font-medium">
                        Request ID: {batchRequestId}
                      </p>
                      <p className="text-sm text-gray-500 mt-1">
                        Operator: {selectedItems[0]?.client?.name}
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        setBatchRequestId("");
                        setSelectedItems([]);
                      }}
                      className="text-red-500 hover:text-red-700"
                    >
                      <BiX size={20} />
                    </button>
                  </div>
                </div>
              ) : (
                <p className="text-sm text-gray-500">No batches selected</p>
              )}
            </div>

            {/* Individual Items Section */}
            <div>
              <h4 className="text-lg font-semibold mb-4">
                Selected Items ({selectedItems?.length})
              </h4>
              <div className="space-y-2 max-h-[400px] overflow-y-auto">
                {selectedItems?.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between p-2 bg-gray-50 rounded"
                  >
                    <div className="flex items-center space-x-2">
                      <img
                        src={item?.stock?.image || noImage}
                        className="w-8 h-8 rounded-full"
                        alt={item?.stock?.asset_name}
                      />
                      <div>
                        <p className="text-sm font-medium">
                          {truncateWithTooltip(item?.stock?.asset_name)}
                        </p>
                        <p className="text-xs text-gray-500">
                          Qty: {item?.nuims_quantity}
                        </p>
                        <p className="text-xs text-gray-500">
                          Operator: {item?.client?.name}
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={() => handleRemoveItem(item?.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <BiX size={16} />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-6">
              <CustomButton
                buttonText={id ? "Update Lot" : "Create Lot"}
                onClick={handleCreateLot}
                isLoading={isCreating}
                disabled={
                  !lotName || (!batchRequestId && selectedItems?.length === 0)
                }
                className="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default CreateLot;
