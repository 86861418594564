import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetLotsManager = ({
  page = "",
  searchQuery = "",
  enabled = true,
  pageSize = "15",
  inEvent = "",
  date_after = "",
  date_before = "",
}) => {
  return useQuery(
    ["lots", page, inEvent, date_after, date_before, searchQuery],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/lots?page=${page}&is_in_event=${inEvent}&date_after=${date_after}&date_before=${date_before}&size=${pageSize}${searchQuery}`
          ),
        ];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    }
  );
};

export default useGetLotsManager;
