import React, { useState } from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import SelectWithFullBorder from "../../../generalComponents/SelectWithFullBorder";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
// import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import TabsManagement from "../../../generalComponents/TabsManagement";
// import { BiDownload } from "react-icons/bi";
import InventoryOverview from "../components/InventoryOverview";
import InventoryStatistics from "../components/OperatorCharts";
import OperatorStatistics from "../components/OperatorStatistics";
import useGetClientsManager from "../controller/getClientsController";
import DisposalOverview from "../components/DisposalOverview";
import MarketOverview from "../components/MarketOverview";
import ExchangeOverview from "../components/ExchangeOverview";
import { useClientType } from "../../../constants/useClientTypes";
import BorrowOverview from "../components/BorrowOverview";

const DashboardPage = () => {
  const [selectedOperator, setSelectedOperator] = useState("all");
  const [currentView, setCurrentView] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { isOperator, isClient } = useClientType();

  const { data: operator } = useGetClientsManager({
    type: "OPERATOR",
    enabled: isClient,
  });

  const operatorOptions = [
    { label: "All Operators", value: "all" },
    ...(operator?.data?.results?.map((operator) => ({
      label: operator.name,
      value: operator.id,
    })) || []),
  ];

  const tabOptions = [
    "Inventory Overview",
    "Disposal Overview",
    "Marketplace Overview",
    "Exchange Overview",
    "Borrow Overview",
  ];

  return (
    <BaseDashboardNavigation title={"Dashboard"}>
      <div className="p-4 bg-gray-50">
        {/* Header Section */}
        <div>
          {isClient && (
            <div className="flex w-full gap-4 bg-white border border-gray-300 p-4 rounded-lg mb-6 justify-between items-center">
              <div className="flex items-center space-x-4 justify-center align-middle">
                <div>
                  <p className="text-[16px] semi-bold">Select Operator</p>
                </div>

                <SelectWithFullBorder
                  selectOptions={operatorOptions}
                  value={selectedOperator}
                  onChange={(e) => setSelectedOperator(e.target.value)}
                  // placeholder="Select Operator"
                  className="appearance-none bg-white border border-gray-200 rounded-md py-4 px-4 pr-8 text-gray-700 leading-tight focus:outline-none focus:border-green-500"
                />
              </div>
              <div className="relative">
                <div className="flex relative justify-center items-center gap-x-2">
                  <div>
                    <div className="flex items-center space-x-4 justify-center align-middle">
                      <span className="text-[16px] semi-bold">
                        Select Date Range:
                      </span>
                      <InputWithFullBoarder
                        className="appearance-none bg-white border border-gray-200 rounded-md py-4 px-4 pr-8 text-gray-700 leading-tight focus:outline-none focus:border-green-500"
                        type="date"
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                      />

                      <span className="whitespace-nowrap">to</span>
                      <InputWithFullBoarder
                        className="appearance-none bg-white border border-gray-200 rounded-md py-4 px-4 pr-8 text-gray-700 leading-tight focus:outline-none focus:border-green-500"
                        type="date"
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  {/* <SelectWithFullBorder
                  selectOptions={operatorOptions}
                  value={selectedOperator}
                  onChange={(e) => setSelectedOperator(e.target.value)}
                  placeholder="Select Operator"
                  className="appearance-none bg-white border border-gray-200 rounded-md py-4 px-4 pr-8 text-gray-700 leading-tight focus:outline-none focus:border-green-500"
                /> */}
                </div>
              </div>
            </div>
          )}

          <div className="flex items-center gap-4">
            {/* <div className="flex -space-x-2">
              {[...Array(4)].map((_, i) => (
                <div
                  key={i}
                  className="w-12 h-12 rounded-full bg-gray-200 border-2 border-white"
                />
              ))}
              <div className="w-12 h-12 rounded-full bg-brandGreen border-2 border-white flex items-center justify-center text-white text-sm">
                +10
              </div>
            </div> */}
            {/* <ButtonWithIcon
              buttonText={"Download Report"}
              icon={BiDownload}
              className={
                "flex items-center gap-2 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors"
              }
            /> */}
          </div>
        </div>

        {/* Title Section */}
        {isClient && (
          <div>
            <h1 className="text-2xl font-semibold mb-2">
              {selectedOperator === "all" || !selectedOperator
                ? "All Operators"
                : operator?.data?.results?.find(
                    (op) => op.id === selectedOperator
                  )?.name || "Selected Operator"}
            </h1>
            <p className="text-gray-600 mb-6">
              Showing statistics for{" "}
              {selectedOperator === "all" || !selectedOperator
                ? "all operators"
                : operator?.data?.results?.find(
                    (op) => op.id === selectedOperator
                  )?.name || "selected operator"}
            </p>
          </div>
        )}

        {/* Tabs Section */}
        <TabsManagement
          currentView={currentView}
          setCurrentView={setCurrentView}
          options={tabOptions}
        />

        {/* Content Section */}
        {currentView === 0 && (
          <InventoryOverview
            startDate={startDate}
            endDate={endDate}
            operatorId={selectedOperator === "all" ? "" : selectedOperator}
          />
        )}
        {currentView === 1 && (
          <DisposalOverview
            startDate={startDate}
            endDate={endDate}
            operatorId={selectedOperator === "all" ? "" : selectedOperator}
          />
        )}
        {currentView === 2 && (
          <MarketOverview
            startDate={startDate}
            endDate={endDate}
            operatorId={selectedOperator === "all" ? "" : selectedOperator}
          />
        )}
        {currentView === 3 && (
          <ExchangeOverview
            startDate={startDate}
            endDate={endDate}
            operatorId={selectedOperator === "all" ? "" : selectedOperator}
          />
        )}
        {currentView === 4 && (
          <BorrowOverview
            startDate={startDate}
            endDate={endDate}
            operatorId={selectedOperator === "all" ? "" : selectedOperator}
          />
        )}
      </div>
      {isClient && (
        <div className="grid grid-cols-1 p-4">
          <OperatorStatistics
            startDate={startDate}
            endDate={endDate}
            currentView={currentView}
          />
        </div>
      )}
      {isOperator && (
        <div className="grid grid-cols-2 p-4">
          <InventoryStatistics startDate={startDate} endDate={endDate} />
        </div>
      )}
    </BaseDashboardNavigation>
  );
};

export default DashboardPage;
