import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalManagement from "../../../generalComponents/ModalManagement";
import CustomButton from "../../../generalComponents/Button";
import ReceiptUploadModal from "./ReceiptUploadModal";
import useGetRemitaTransaction from "../../../constants/remita/getRemitaTransactionController";
import { PostRemitaTransaction } from "../../../constants/remita/postRemitaTransactionController";
import { formatNumber } from "../../../utils/formatNumber";
import config from "../../../config";
import PaymentSuccessModal from "./PaymentSuccessModal";
import RemitaConfirmationModal from "./RemitaConfirmationModal";
import RemitaLoadingModal from "../../cart/components/RemitaLoadingModal";

const AuctionFeeModal = ({
  eventName,
  entryFee,
  eventId,
  existingRRR,
  trxId,
}) => {
  const [isLoadingScript, setIsLoadingScript] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isGeneratingRRR, setIsGeneratingRRR] = useState(false);
  const [remitaDetails, setRemitaDetails] = useState({
    rrr: existingRRR || null,
    transactionId: trxId || null,
  });

  const { isLoading: initLoading, refetch: initiateRemita } =
    useGetRemitaTransaction({
      id: eventId,
    });

  const {
    verifyRemita,
    isLoading: verifyLoading,
    isSuccess,
  } = PostRemitaTransaction({
    id: eventId,
  });

  useEffect(() => {
    const loadRemitaScript = () => {
      const existingScript = document.querySelector(
        'script[src*="remita-pay-inline"]'
      );
      if (existingScript) {
        document.body.removeChild(existingScript);
        setIsScriptLoaded(false);
      }

      const script = document.createElement("script");
      script.src = config.REMITA_SCRIPT_URL;
      script.async = true;

      script.onload = () => {
        setTimeout(() => {
          if (
            window.RmPaymentEngine &&
            typeof window.RmPaymentEngine.init === "function"
          ) {
            setIsScriptLoaded(true);
          } else {
            toast.error(
              "Payment system initialization failed. Please refresh the page."
            );
            setIsScriptLoaded(false);
          }
        }, 1000);
      };

      script.onerror = () => {
        toast.error("Failed to load payment system. Please try again.");
        setIsScriptLoaded(false);
      };

      document.body.appendChild(script);
      return script;
    };

    const script = loadRemitaScript();
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("auction_fee_modal").close();
    }
  }, [isSuccess]);

  const initializePayment = async () => {
    try {
      const response = await initiateRemita();

      if (
        !response?.data?.data?.gateway_reference ||
        !response?.data?.data?.reference
      ) {
        throw new Error("Invalid payment response from server");
      }

      return {
        rrr: response.data.data.gateway_reference,
        transactionId: response.data.data.reference,
      };
    } catch (error) {
      toast.error(error.message || "Failed to initialize payment");
      throw error;
    }
  };

  const handlePaymentVerification = async (rrr) => {
    try {
      await verifyRemita({
        remita_reference: rrr,
        is_paid: true,
      });
      toast.success("Payment verified successfully");
      document.getElementById("payment_success_modal").showModal();
    } catch (error) {
      toast.error("Payment verification failed. Please contact support.");
    }
  };

  const handleInitiateRemita = async () => {
    if (!isScriptLoaded) {
      toast.error("Payment system is not ready. Please refresh the page.");
      return;
    }

    // Close main payment selection modal
    document.getElementById("auction_fee_modal").close();

    // Show loading modal
    document.getElementById("loading_modal").showModal();

    setIsGeneratingRRR(true);

    try {
      // If we already have RRR and transaction ID, use them
      if (existingRRR && trxId) {
        setRemitaDetails({
          rrr: existingRRR,
          transactionId: trxId,
        });

        // Short timeout to show loading state briefly
        setTimeout(() => {
          setIsGeneratingRRR(false);
          document.getElementById("loading_modal").close();
          document.getElementById("remita_confirmation_modal").showModal();
        }, 500);
      } else {
        // Otherwise initiate a new payment
        const paymentDetails = await initializePayment();
        setRemitaDetails(paymentDetails);
        setIsGeneratingRRR(false);

        // Close loading modal
        document.getElementById("loading_modal").close();

        // Open confirmation modal
        document.getElementById("remita_confirmation_modal").showModal();
      }
    } catch (error) {
      toast.error(error.message || "Failed to initialize payment");
      setIsGeneratingRRR(false);

      // Close loading modal
      document.getElementById("loading_modal").close();

      // Reopen the main modal
      document.getElementById("auction_fee_modal").showModal();
    }
  };

  const handleRemitaPayment = () => {
    if (
      !window.RmPaymentEngine ||
      typeof window.RmPaymentEngine.init !== "function"
    ) {
      toast.error("Payment system is not ready. Please refresh the page.");
      return;
    }

    // Close the confirmation modal
    document.getElementById("remita_confirmation_modal").close();

    setIsLoadingScript(true);

    try {
      // Initialize the Remita payment engine
      const paymentEngine = window.RmPaymentEngine.init({
        key: config.REACT_APP_REMITA_PUBLIC_KEY,
        processRrr: true,
        transactionId: remitaDetails.transactionId,
        extendedData: {
          customFields: [{ name: "rrr", value: remitaDetails.rrr }],
        },
        onSuccess: async (response) => {
          await handlePaymentVerification(remitaDetails.rrr);
        },
        onError: (response) => {
          toast.error(response?.message || "Payment failed");
          // Reopen the main modal
          document.getElementById("auction_fee_modal").showModal();
        },
        onClose: () => {
          // Reopen the main modal
          document.getElementById("auction_fee_modal").showModal();
        },
      });

      // Show the payment widget
      paymentEngine.showPaymentWidget();
    } catch (error) {
      toast.error(error.message || "Payment initialization failed");
      // Reopen the main modal
      document.getElementById("auction_fee_modal").showModal();
    } finally {
      setIsLoadingScript(false);
    }
  };

  return (
    <div>
      {/* Main payment selection modal */}
      <ModalManagement id="auction_fee_modal">
        <div className="md:w-[400px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <p className="text-18px font-semibold py-4 text-center">
            How would you like to pay for the ₦{formatNumber(entryFee)} auction
            fee for {eventName} event?
          </p>
          <div className="flex justify-center space-x-2 mt-4 w-full">
            <CustomButton
              buttonText="Bank Transfer"
              textColor="#368618"
              className="bg-white border border-brandGreen text-[#368618]"
              onClick={() => {
                document.getElementById("receipt_upload").showModal();
                document.getElementById("auction_fee_modal").close();
              }}
            />
            <CustomButton
              buttonText={
                initLoading || isGeneratingRRR
                  ? "Processing..."
                  : "Pay Via Remita"
              }
              onClick={handleInitiateRemita}
              disabled={initLoading || isGeneratingRRR || !isScriptLoaded}
            />
          </div>
        </div>
      </ModalManagement>

      {/* Loading modal */}
      <RemitaLoadingModal />

      {/* Remita confirmation modal with RRR */}
      <RemitaConfirmationModal
        eventName={eventName}
        entryFee={entryFee}
        remitaRRR={remitaDetails.rrr}
        transactionId={remitaDetails.transactionId}
        onPayNow={handleRemitaPayment}
        isLoading={isLoadingScript || verifyLoading}
      />

      {/* Receipt upload modal */}
      <ReceiptUploadModal entryFee={entryFee} />

      {/* Payment success modal */}
      <PaymentSuccessModal
        eventName={eventName}
        entryFee={entryFee}
        onClose={() => window.location.reload()}
      />
    </div>
  );
};

export default AuctionFeeModal;
