import React from "react";
import Tabs from "../../../../generalComponents/Tabs";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import ItemsByRequestId from "./ItemsByRequestId";
import { useLocation } from "react-router-dom";
import GoBackButton from "../../../../generalComponents/GoBackButton";
import { editIcon } from "../../../../assets/icons";
import ItemsByRequestIdDetails from "./ItemsByRequestIdDetails";
import StatusButton from "../../../../generalComponents/StatusButton";
import useGetDisposalBatchByIdManager from "../controllers/getDisposalBatchByIdController";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import { ApproveDisposalManager } from "../controllers/approveDisposalController";
import { RejectDisposalManager } from "../controllers/rejectDisposalController";
// import useGetDisposalBatchItemsManager from "../controllers/getDisposalBatchItemsController";
import { hasPermissions } from "../../../../constants/permissions";

const BatchDetails = () => {
  const location = useLocation();
  const itemId = location.state?.itemId;
  // const approvalStatus = location.state?.approvalStatus;

  const { data: batchDetails } = useGetDisposalBatchByIdManager(itemId);

  // const { data: batchItems } = useGetDisposalBatchItemsManager(itemId);

  const { approveDisposal, isLoading: approving } =
    ApproveDisposalManager(itemId);
  const { rejectDisposal, isLoading: rejecting } =
    RejectDisposalManager(itemId);

  const requestData = batchDetails?.data;

  // const handleApproveRequest = () => {
  //   const itemIds = batchItems?.data?.results.map((item) => item.id) || [];
  //   const payload = {
  //     items: itemIds,
  //   };

  //   approveDisposal(payload);
  // };

  const handleApproveRequest = () => {
    approveDisposal();
  };

  const handleDeclineRequest = () => rejectDisposal();

  const tabsData = [
    {
      label: "Batch Details",
      component: (
        <ItemsByRequestIdDetails
          requestLetter={requestData?.request_letter_url}
          approvalId={requestData?.approval_id}
          approvalLetter={requestData?.approval_letter_url}
          otherDocuments={requestData?.documents}
          // disposalInspection={disposalInspection}
        />
      ),
    },
    {
      label: "Batch List",
      component: (
        <ItemsByRequestId
          requestLetter={requestData?.request_letter_url}
          requestStatus={requestData?.request_status}
          approvalStatus={requestData?.approval_status}
        />
      ),
    },
  ];
  // Derive the current approval level from the request's status.
  const approvalStatus = requestData?.approval_status;
  const currentLevel =
    approvalStatus === "PENDING"
      ? 1
      : approvalStatus === "LEVEL_ONE_APPROVED"
      ? 2
      : approvalStatus === "LEVEL_TWO_APPROVED"
      ? 3
      : null;

  // Only show buttons if the request is waiting on a specific level and
  // the user has the corresponding level's approval permission.
  let showButtons = false;
  if (currentLevel === 1) {
    showButtons = hasPermissions(["approve_level_one_stock_disposal_item"]);
  } else if (currentLevel === 2) {
    showButtons = hasPermissions(["approve_level_two_stock_disposal_item"]);
  } else if (currentLevel === 3) {
    showButtons = hasPermissions(["approve_level_three_stock_disposal_item"]);
  }

  return (
    <BaseDashboardNavigation>
      <div>
        <GoBackButton />
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full pt-3">
          <div className="flex items-center mb-2 md:mb-0 gap-x-2">
            <h3 className="text-[24px] font-bold">
              Request ID: {requestData?.request_id}{" "}
            </h3>
            <StatusButton status={requestData?.approval_status} />
            <img
              src={editIcon}
              onClick={() => {
                // setRequestId(request_id)
                document.getElementById("edit_requestId").showModal();
              }}
              className="cursor-pointer"
              alt="Edit"
            />
          </div>
          <div
            className={
              showButtons
                ? "flex items-center space-x-[10px] mt-1 md:mt-0"
                : "hidden"
            }
          >
            <ButtonWithIcon
              buttonText={"Decline Request"}
              className={"bg-red-600"}
              radius={"md"}
              onClick={handleDeclineRequest}
              isLoading={rejecting}
            />
            <ButtonWithIcon
              buttonText={"Approve Request"}
              radius={"md"}
              onClick={handleApproveRequest}
              isLoading={approving}
            />
          </div>
        </div>

        <Tabs tabsData={tabsData} />
      </div>
    </BaseDashboardNavigation>
  );
};

export default BatchDetails;
