import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetTicketsManager = ({
  id,
  page = 1,
  searchQuery = "",
  enabled = true,
  pageSize = "15",
  status = "",
  is_mine = "",
}) => {
  return useQuery(["support", page, searchQuery, status, is_mine], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(
          `/support/tickets?status=${status}&is_mine=${is_mine}&page=${page}&size=${pageSize}${searchQuery}`
        ),
      ];
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetTicketsManager;
