import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetStockActivityLogsManager = ({
  page,
  searchQuery = "",
  enabled = true,
  pageSize = "15",
  stock_id = "",
}) => {
  return useQuery(["stock", page, stock_id, searchQuery], async () => {
    try {
      const response = await AxiosWithToken.get(
        `/stock/movement/logs/?stock_id=${stock_id}&page=${page}&size=${pageSize}${searchQuery}`
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Sorry: ${error.response?.data?.message || "An error occurred"}`
      );
    }
  });
};

export default useGetStockActivityLogsManager;
