import React from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import CustomButton from "../../../generalComponents/Button";
import { formatNumber } from "../../../utils/formatNumber";

const PaymentSuccessModal = ({ eventName, entryFee, onClose }) => {
  return (
    <ModalManagement id="payment_success_modal">
      <div className="md:w-[400px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
        <div className="flex justify-center mb-4">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>
        <p className="text-2xl font-bold py-2 text-center text-green-600">
          Payment Successful!
        </p>
        <p className="text-gray-700 text-center px-6 mb-4">
          Your payment of ₦{formatNumber(entryFee)} has been successfully
          processed.
        </p>
        <div className="flex justify-center mb-6">
          <CustomButton
            buttonText="Continue"
            onClick={() => {
              document.getElementById("payment_success_modal").close();
              if (onClose) onClose();
            }}
          />
        </div>
      </div>
    </ModalManagement>
  );
};

export default PaymentSuccessModal;
