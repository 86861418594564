import { useState, useEffect } from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import { BiPlus } from "react-icons/bi";
import SelectWithFullBorder from "../../../generalComponents/SelectWithFullBorder";
import useGetWarehouseManager from "../../warehouse/controllers/getWarehouseController";
import { CreateSalesManager } from "../controllers/createSalesController";

const SalesModal = ({ stockId, assetName }) => {
  const [stockQuantity, setStockQuantity] = useState(0);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);

  const { createSales, isSuccess } = CreateSalesManager();

  const { data: wareHouses } = useGetWarehouseManager({
    enabled: true,
    page: "",
  });

  const handleSales = () => {
    const payload = {
      stock: stockId,
      quantity: stockQuantity,
      warehouse: selectedWarehouse,
    };

    createSales(payload);
  };

  const clearFields = () => {
    setStockQuantity(0);
    setSelectedWarehouse("");
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("sales_modal").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement
        id={"sales_modal"}
        title={"Buy Asset"}
        onClose={clearFields}
      >
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-2">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Quantity"}
                placeholder={"Enter Quantity"}
                type={"number"}
                value={stockQuantity}
                onChange={(e) => setStockQuantity(e.target.value)}
                required
              />
            </div>
          </div>
          {/* <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <SelectWithFullBorder
                label={"Select Exchange Asset"}
                selectOptions={
                  stockItems?.data?.results?.map((stock) => ({
                    label: stock?.asset_name,
                    value: stock?.stock_id,
                  })) || []
                }
                value={selectedExchangeAsset}
                onChange={(e) => setSelectedExchangeAsset(e.target.value)}
                placeholder="Select Exchange Asset"
              />
            </div>
          </div> */}
          {/* <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Purchase Amount"}
                placeholder={"Enter Amount"}
                type={"number"}
                value={purchaseAmount}
                onChange={(e) => setPurchaseAmount(e.target.value)}
                required
              />
            </div>
          </div> */}
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <SelectWithFullBorder
                selectOptions={[
                  { label: "Select Warehouse", value: "" },
                  ...(wareHouses?.data?.results?.map((warehouse) => ({
                    label: warehouse?.name,
                    value: warehouse?.id,
                  })) || []),
                ]}
                value={selectedWarehouse}
                onChange={(e) => setSelectedWarehouse(e.target.value)}
                placeholder="Select Warehouse"
                label="Select Warehouse"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 h-[40px] w-auto"}
              onClick={() => {
                document.getElementById("sales_modal").close();
                clearFields();
              }}
            />
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Buy Asset"}
              onClick={handleSales}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default SalesModal;
