import React, { useEffect, useState } from "react";
// import CustomButton from "../../../generalComponents/Button";
import {
  homeImage1,
  homeImage2,
  homeImage3,
  partnerLogo1,
  partnerLogo2,
  partnerLogo3,
  partnerLogo4,
  partnerLogo5,
  partnerLogo6,
  partnerLogo7,
} from "../../../assets/images";

const HomeHero = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const partnerLogos = [
    partnerLogo1,
    partnerLogo2,
    partnerLogo3,
    partnerLogo4,
    partnerLogo5,
    partnerLogo6,
    partnerLogo7,
  ];

  // Auto scroll effect
  useEffect(() => {
    const interval = setInterval(() => {
      setScrollPosition((prev) => {
        const newPosition = prev + 1;
        return newPosition >= partnerLogos.length ? 0 : newPosition;
      });
    }, 3000); // Scroll every 3 seconds

    return () => clearInterval(interval);
  }, [partnerLogos.length]);

  return (
    <section className="bg-[#F8FDF6] py-16 overflow-hidden">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Content */}
        <div className="text-center mb-16">
          <h1 className="font-clash font-medium text-[56px] leading-[1.2] mb-4 ">
            e-MMS: Your Ultimate Material{" "}
            <span className="text-brandGreen">Management</span> Solution
          </h1>
          <p className="font-instrument font-regular text-16px text-black  mb-12 max-w-3xl mx-auto">
            Experience unified, efficient, and transparent material management
            with e-MMS. Our cutting-edge solution provides 360-degree visibility
            into inventory across the oil and gas sector, ensuring seamless
            operations and optimal resource utilization.
          </p>
          {/* <CustomButton buttonText="Learn More" radius="md" /> */}
        </div>

        {/* Image Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16">
          {[homeImage1, homeImage2, homeImage3].map((image, index) => (
            <div
              key={index}
              className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-lg"
            >
              <img
                src={image}
                alt={`Feature ${index + 1}`}
                className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
              />
            </div>
          ))}
        </div>

        {/* Partners Section */}
        <div className="flex mt-16 items-center">
          <div>
            <h2 className="font-instrument font-regular text-16px text-gray-600 text-left mb-8">
              Trusted Partnership with 100+ Companies
            </h2>
          </div>

          <div className="relative overflow-hidden">
            <div
              className="flex gap-8 transition-transform duration-500 ease-in-out"
              style={{
                transform: `translateX(-${scrollPosition * 160}px)`,
                width: `${partnerLogos.length * 160}px`,
              }}
            >
              {partnerLogos.map((logo, index) => (
                <div key={index} className="w-40 h-20 flex-shrink-0">
                  <img
                    src={logo}
                    alt={`Partner Logo ${index + 1}`}
                    className="w-full h-full object-contain filter hover:brightness-110 transition-all duration-300"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
