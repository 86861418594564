import React from "react";

const InputWithFullBorder = ({
  id,
  type,
  value,
  onChange,
  label,
  checked,
  onClick,
  className,
  hasSuffix,
  placeholder,
  row = "3",
  icon,
  accept,
  isTextArea = false,
  wrapperClassName,
  required = false,
  maxLength,
  ...props
}) => {
  // Handler for input validation
  const handleInputChange = (e) => {
    onChange(e);
  };

  return (
    <div className={`flex flex-col mb-4 ${wrapperClassName}`}>
      <label className="text-13px md:text-14px font-medium mb-2" htmlFor={id}>
        {label && (
          <>
            {label}
            {required && <span className="text-red-700 text-[14px]">*</span>}
          </>
        )}
      </label>
      {isTextArea ? (
        <textarea
          id={id}
          className={`border border-lightGrey bg-lightGrey/30 p-2 rounded-md ${className} outline-none focus:outline-none`}
          cols="50"
          rows={row}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
          {...props}
        ></textarea>
      ) : hasSuffix ? (
        <div
          className={`border border-lightGrey bg-lightGrey/30 p-2 rounded-md placeholder:text-[12px] ${className} outline-none focus:outline-none flex items-center justify-between`}
        >
          <input
            onClick={onClick}
            type={type}
            placeholder={placeholder}
            id={id}
            accept={accept}
            checked={checked}
            color="white"
            value={value}
            onChange={handleInputChange}
            required={required}
            maxLength={maxLength}
            {...props}
            className={`bg-transparent outline-none focus:outline-none w-full placeholder:text-[12px] mr-4 `}
          />
          <div>{icon}</div>
        </div>
      ) : (
        <input
          onClick={onClick}
          type={type}
          id={id}
          accept={accept}
          placeholder={placeholder}
          checked={checked}
          color="white"
          value={value}
          onChange={handleInputChange}
          required={required}
          maxLength={maxLength}
          {...props}
          className={
            type !== "password" &&
            `border border-lightGrey bg-lightGrey/30 p-2 rounded-md ${className} placeholder:text-[12px] outline-none focus:outline-none`
          }
        />
      )}
    </div>
  );
};

export default InputWithFullBorder;
