import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetInspectionsManager = ({
  isStockOwnerClient = "",
  isVisitingClient = "",
  page = "",
  assetName = "",
  status = "",
}) => {
  return useQuery(
    [
      page,
      isStockOwnerClient,
      isVisitingClient,
      assetName,
      status,
      "inspections",
    ],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/inspections/?status=${status}&page=${page}&asset_name=${assetName}&is_stock_owner_client=${isStockOwnerClient}&is_visiting_client=${isVisitingClient}`
          ),
        ];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    }
  );
};

export default useGetInspectionsManager;
