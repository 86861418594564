import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetRequestById = (type, id) => {
  const queryKey = type === "borrow" ? [type] : [type, id];

  const endpoint =
    type === "borrow"
      ? `/stock/borrows/${id}/`
      : type === "exchange"
      ? `/stock/exchange/${id}/`
      : type === "sales"
      ? `/stock/sales/${id}/`
      : null;

  return useQuery(queryKey, async () => {
    if (!endpoint) throw new Error("Invalid request type");

    try {
      const [response] = [await AxiosWithToken.get(endpoint)];
      return response.data;
    } catch (error) {
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetRequestById;
