import React, { useEffect, useState } from "react";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import useDebounce from "../../../utils/UseDebounce";
import SearchField from "../../../generalComponents/SearchField";
import AdminDeleteConfirmationModal from "../../../generalComponents/AdminDeleteConfirmationModal";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import useGetLotsManager from "../controllers/getLotsController";
import { DeleteLotsManager } from "../controllers/deleteLotsController";
import { formatNumber } from "../../../utils/formatNumber";
import { formatDateAndTime } from "../../../utils/formatDateAndTime";
import { noImage } from "../../../assets/images";
import { hasPermissions } from "../../../constants/permissions";
import ShareWarRoomModal from "../components/ShareWarRoomModal";

const LotsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [shareLink, setShareLink] = useState("");
  const [dateBefore, setDateBefore] = useState("");
  const [dateAfter, setDateAfter] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const navigate = useNavigate();

  const { data, isLoading } = useGetLotsManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
    date_after: dateAfter,
    date_before: dateBefore,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "S/N",
    "Image",
    "Lot Name",
    "Start Amount",
    "Reserve Amount",
    "Incremental Amount",
    "Start Date",
    "End Date",
    "Action",
  ];

  const options = [
    { label: "War Room", permissions: ["view_lot", "view_lotitem"] },
    { label: "Share Link", permissions: ["view_lot"] },
    { label: "Edit lot", permissions: ["change_lot"] },
    { label: "Delete lot", permissions: ["delete_lot"] },
  ];

  const getFormattedValue = (el, index) => {
    return [
      index + 1,
      <span>
        <img src={el?.image_url || noImage} alt="Item" className="w-12" />
      </span>,
      el?.name,
      <span>{formatNumber(el?.starting_amount)}</span>,
      <span>{formatNumber(el?.reserve_amount)}</span>,
      el?.incremental_amount,
      <span>{formatDateAndTime(el?.start_datetime)} </span>,
      <span>{formatDateAndTime(el?.end_datetime)}</span>,
    ];
  };

  const {
    deleteLot,
    isLoading: deleting,
    isSuccess: deleted,
  } = DeleteLotsManager({
    id: selected,
  });

  const handleDelete = async () => {
    await deleteLot();
  };

  useEffect(() => {
    if (deleted) {
      document.getElementById("admin_delete").close();
    }
  }, [deleted]);

  return (
    <BaseDashboardNavigation>
      <div>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
          <div className="flex items-center mb-2 md:mb-0">
            <h3 className="text-[24px] font-bold">Lots Management</h3>
          </div>
        </div>
        <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="">
              <SearchField
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />{" "}
            </div>
            <div className="flex items-center space-x-4 justify-center align-middle">
              <span className="text-[14px] ">Select Date Range:</span>
              <InputWithFullBoarder
                className="flex-1"
                type="date"
                value={dateAfter}
                onChange={(e) => {
                  setDateAfter(e.target.value);
                }}
              />

              <span className="whitespace-nowrap">to</span>
              <InputWithFullBoarder
                className="flex-1"
                type="date"
                value={dateBefore}
                onChange={(e) => {
                  setDateBefore(e.target.value);
                }}
              />
            </div>
            <div
              className={`${
                hasPermissions(["add_lot"])
                  ? "flex space-x-[10px] mt-3 md:mt-0"
                  : "hidden"
              } `}
            >
              <ButtonWithIcon
                buttonText={"Create Lot"}
                radius={"md"}
                icon={BiPlus}
                onClick={() => {
                  // document.getElementById("create_lot").showModal();
                  navigate("/lots/create-lot");
                }}
              />
            </div>
          </div>
          <div className="h-[67vh] w-full relative">
            {
              <TablesComponent
                isLoading={isLoading}
                data={data?.data?.results}
                getFormattedValue={getFormattedValue}
                headers={headers}
                options={options}
                popUpFunction={(option, index, val) => {
                  console.log(option, index, val);
                  setSelected(val);
                  if (index === 0) {
                    navigate(`/lots/${val.id}/war-room`);
                  }
                  if (index === 1) {
                    const shareLink = `${window.location.origin}/lots/${val.id}/war-room`;
                    setShareLink(shareLink); // Add this state at the top
                    document.getElementById("share_warroom").showModal();
                  }
                  if (index === 2) {
                    navigate(`/lots/edit-lot/${val.id}`);
                  }
                  if (index === 3) {
                    setSelected(val.id);
                    document.getElementById("admin_delete").showModal();
                  }
                }}
                showCheckBox={false}
                // Close popup function
              />
            }
          </div>
          <div className="flex items-center justify-between mt-4">
            <p className="text-14px text-brandBlack">
              {" "}
              {startItem} - {endItem} of {data?.data?.pagination?.count} Events
            </p>
            <PaginationRounded
              defaultPage={data?.data?.pagination?.page}
              count={data?.data?.pagination?.total_pages}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        </div>
      </div>
      <AdminDeleteConfirmationModal
        body={`Are you sure you want to delete this auction?`}
        title={"Delete Auction"}
        buttonText={"Yes, Delete"}
        isLoading={deleting}
        onClick={handleDelete}
      />
      <ShareWarRoomModal link={shareLink} />
    </BaseDashboardNavigation>
  );
};

export default LotsPage;
