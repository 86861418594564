import usePatchManager from "../../../constants/controller_templates/patch_controller_template";

export const PatchAuctionManager = ({ id }) => {
  const { patchCaller, isLoading, isSuccess, error, data } = usePatchManager(
    `/auctions/${id}/`,
    ["auction"],
    false,
    true
  );
  const patchAuction = async (details) => {
    try {
      await patchCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    patchAuction,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
