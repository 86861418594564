import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import { CreateAuctionManager } from "../controllers/createAuctionController";
// import { UpdateAuctionManager } from "../controllers/updateAuctionController";
import SelectWithFullBorder from "../../../generalComponents/SelectWithFullBorder";
import useGetLotsManager from "../../lots/controllers/getLotsController";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import GoBackButton from "../../../generalComponents/GoBackButton";
import { BiPlusCircle, BiTrash } from "react-icons/bi";
import CustomToggle from "../../../generalComponents/CustomToggle";
import useGetAuctionByIdManager from "../controllers/getAuctionByIdController";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import useGetClientsManager from "../../accessControl/clients/controllers/getClientsController";
import MultiSelectInfiniteScroll from "../../../generalComponents/MultiSelectInfiniteScroll";
import { PatchAuctionManager } from "../controllers/patchAuctionController";

const CreateAuction = ({ details }) => {
  const { id } = useParams();
  const [eventName, setEventName] = useState("");
  const [eventType, setEventType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [entryFee, setEntryFee] = useState(0);
  const [privateEvent, setPrivateEvent] = useState(false);
  const [premiumEvent, setPremiumEvent] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [allClients, setAllClients] = useState([]);

  const [lots, setLots] = useState([
    {
      lot: "",
      starting_amount: 0,
      reserve_amount: 0,
      closing_amount: 0,
      incremental_amount: 0,
      is_buy_now: false,
    },
  ]);

  const navigate = useNavigate();

  const { createAuction, isLoading, isSuccess } = CreateAuctionManager();
  // const { updateAuction, isSuccess: updated } = UpdateAuctionManager({
  //   id: id,
  // });
  const { patchAuction, isSuccess: patched } = PatchAuctionManager({
    id: id,
  });

  const { data: auctionEvent } = useGetAuctionByIdManager(id);

  const { data: Lots } = useGetLotsManager({
    enabled: true,
    pageSize: "all",
    inEvent: false,
  });

  const { data: vendors, isLoading: isLoadingVendors } = useGetClientsManager({
    type: "VENDOR",
    enabled: true,
    page: page,
    pageSize: 100,
  });

  useEffect(() => {
    if (vendors?.data?.results) {
      setAllClients((prev) => {
        const newClients = vendors.data.results.map((client) => ({
          value: client.id,
          label: client.name,
        }));

        // Merge new clients with existing ones, removing duplicates
        const uniqueClients = [...prev];
        newClients.forEach((client) => {
          if (
            !uniqueClients.some((existing) => existing.value === client.value)
          ) {
            uniqueClients.push(client);
          }
        });
        return uniqueClients;
      });

      setHasMore(vendors.data.next !== null);
    }
  }, [vendors]);

  const handleLoadMore = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const clientOptions = useMemo(
    () =>
      vendors?.data?.results?.map((client) => ({
        value: client.id, // This will be sent to backend
        label: client.name, // This will be displayed in the select
      })) || [],
    [vendors]
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Set image preview
      setImagePreview(URL.createObjectURL(file));

      // Convert to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setImage(base64String); // This will now store the base64 string instead of the file
      };
      reader.readAsDataURL(file);
    }
  };

  // Populate form when editing
  useEffect(() => {
    if (id && auctionEvent) {
      setImagePreview(auctionEvent?.data?.event_image_url);
      setEventName(auctionEvent?.data?.event_name);
      setEventType(auctionEvent?.data?.event_type);
      setStartDate(formatDateForInput(auctionEvent?.data?.start_datetime));
      setEndDate(formatDateForInput(auctionEvent?.data?.end_datetime));
      setEntryFee(auctionEvent?.data?.entry_fee);
      setPrivateEvent(
        eventType === "OFFER" ? true : auctionEvent?.data?.is_private || false
      );
      setParticipants(
        auctionEvent?.data?.participants
          ?.map((participant) => {
            const matchingClient = clientOptions.find(
              (client) => client.label === participant.participant
            );
            return matchingClient?.value;
          })
          .filter(Boolean)
      );
      setPremiumEvent(auctionEvent?.data?.is_premium || false);
      if (auctionEvent?.data?.lots && auctionEvent?.data?.lots.length > 0) {
        const editableLots = auctionEvent.data.lots.map((lot) => ({
          lot: lot.id.toString(),
          lot_name: lot.name, // Add lot name to preserve the selected option
          starting_amount: lot.starting_amount,
          reserve_amount: lot.reserve_amount,
          closing_amount: lot.closing_amount,
          incremental_amount: lot.incremental_amount,
          is_buy_now: lot.is_buy_now || false,
        }));

        setLots(editableLots);
        if (auctionEvent?.data?.image_url) {
          setImagePreview(auctionEvent.data.image_url);
        }
      }
    }
  }, [id, auctionEvent, eventType, clientOptions]);

  // Helper function to format date for datetime-local input
  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().slice(0, 16);
  };

  const auctionType = [
    { label: "Auction", value: "BID" },
    { label: "Make Offer", value: "OFFER" },
  ];

  // Get available lots for a specific index
  const getAvailableLots = (currentIndex) => {
    const currentLot = lots[currentIndex];

    // If editing and we have the lot name, create an option for the current lot
    if (currentLot?.lot && currentLot?.lot_name) {
      return [
        {
          label: currentLot.lot_name,
          value: currentLot.lot,
        },
      ];
    }

    // Get all currently selected lot IDs except the current index
    const selectedLots = lots
      .filter((lot, index) => index !== currentIndex && lot.lot)
      .map((lot) => lot.lot);

    // Return available unselected lots
    return (
      Lots?.data?.results
        ?.filter((lot) => !selectedLots.includes(lot.id.toString()))
        .map((lot) => ({
          label: lot?.name,
          value: lot?.id.toString(),
        })) || []
    );
  };

  const addLot = () => {
    setLots([
      ...lots,
      {
        lot: "",
        starting_amount: "",
        reserve_amount: "",
        closing_amount: "",
        incremental_amount: "",
        is_buy_now: false,
      },
    ]);
  };

  const removeLot = (index) => {
    if (lots.length > 1) {
      const newLots = lots.filter((_, i) => i !== index);
      setLots(newLots);
    }
  };

  const updateLot = (index, field, value) => {
    const newLots = [...lots];
    newLots[index] = {
      ...newLots[index],
      [field]: value,
    };
    setLots(newLots);
  };

  const handleCreateAuction = async () => {
    const auctionData = {
      event_image: image,
      event_name: eventName,
      event_type: eventType,
      entry_fee: entryFee,
      is_private: privateEvent,
      start_datetime: startDate,
      end_datetime: endDate,
      participant_clients: participants,
      lots: lots.map((lot) => ({
        lot: parseInt(lot.lot),
        starting_amount: lot.starting_amount || 0,
        reserve_amount: lot.reserve_amount || 0,
        closing_amount: lot.closing_amount || 0,
        incremental_amount: parseInt(lot.incremental_amount) || 0,
        is_buy_now: lot.is_buy_now,
      })),
    };

    if (auctionEvent) {
      const hasStarted =
        new Date(auctionEvent.data.start_datetime) <= new Date();
      const patchData = hasStarted
        ? {
            event_name: eventName,
            event_image: image,
            start_datetime: startDate,
            end_datetime: endDate,
            // participant_clients: participants,
          }
        : auctionData;

      patchAuction(patchData);
    } else {
      createAuction(auctionData);
    }
  };

  const handleEventTypeChange = (value) => {
    setEventType(value);
    if (value === "OFFER") {
      setPrivateEvent(true);
    }
  };

  useEffect(() => {
    if (parseInt(entryFee) > 0) {
      setPremiumEvent(true);
    }
  }, [entryFee]);

  useEffect(() => {
    if (isSuccess || patched) {
      navigate("/auction");
    }
  }, [isSuccess, patched, navigate]);

  return (
    <BaseDashboardNavigation>
      <div className=" h-auto rounded-[6px] relative flex flex-col">
        <div className="flex flex-col md:flex-row md:space-x-2">
          <div className="flex-1 mt-3">
            <div className="w-full flex items-center justify-between my-5">
              <GoBackButton />
            </div>

            <div className="flex items-center mb-2 md:mb-2">
              <h3 className="text-[24px] font-bold">
                {auctionEvent ? "Edit Auction Event" : "Create Auction Event"}
              </h3>
            </div>
            <div className="bg-white p-4 rounded-md border border-gray-200">
              <div className="my-4 ">
                <label className="block text-sm font-medium text-gray-700">
                  Upload Image
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="mt-2"
                />
                {imagePreview && (
                  <div className="mt-2">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="w-32 h-32 object-cover"
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col md:flex-row md:space-x-2">
                <div className="flex-1">
                  <InputWithFullBoarder
                    label={"Event Name"}
                    placeholder={"Enter Event Name"}
                    type={"text"}
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                    required
                  />
                </div>
                <div className="flex-1">
                  <SelectWithFullBorder
                    label={"Event Type"}
                    selectOptions={auctionType}
                    value={eventType}
                    onChange={(e) => handleEventTypeChange(e.target.value)}
                    placeholder="Select Event Type"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-2 justify-start">
                <div className="flex-1">
                  <CustomToggle
                    label="Premium"
                    enabled={premiumEvent}
                    onChange={setPremiumEvent}
                  />
                </div>
                <div className="flex-1">
                  <CustomToggle
                    label="Private Event"
                    enabled={privateEvent || eventType === "OFFER"}
                    onChange={setPrivateEvent}
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:space-x-2 items-center">
                <div className="flex-1">
                  <InputWithFullBoarder
                    label={"Entry Fee"}
                    placeholder={"Enter Entry Fee"}
                    type={"number"}
                    value={premiumEvent ? entryFee : 0}
                    onChange={(e) => setEntryFee(e.target.value)}
                    required
                    disabled={!premiumEvent}
                  />
                </div>
                <div className="flex-1">
                  <MultiSelectInfiniteScroll
                    label="Invite People"
                    placeholder="Invite Vendors"
                    required
                    selectOptions={allClients}
                    isMulti={true}
                    value={participants.map((id) => ({
                      value: id,
                      label:
                        allClients.find((c) => c.value === id)?.label || id,
                    }))}
                    disabled={eventType === "OFFER" ? false : !privateEvent}
                    onChange={(selected) => {
                      const participantIds = selected.map((item) => item.value);
                      setParticipants((prevParticipants) => {
                        const uniqueParticipants = [
                          ...new Set([...prevParticipants, ...participantIds]),
                        ];
                        return uniqueParticipants;
                      });
                    }}
                    loadMore={handleLoadMore}
                    hasMore={hasMore}
                    isLoading={isLoadingVendors}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-2">
                <div className="flex-1">
                  <InputWithFullBoarder
                    label={"Start Date"}
                    placeholder={"Select Start Date"}
                    type={"datetime-local"}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                  />
                </div>
                <div className="flex-1">
                  <InputWithFullBoarder
                    label={"End Date"}
                    placeholder={"Select End Date"}
                    type={"datetime-local"}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    required
                  />
                </div>
              </div>
              {/* Lots Section */}
              {lots.map((lot, index) => (
                <div
                  key={index}
                  className="mt-4 p-4 border border-gray-200 rounded-lg"
                >
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-semibold">Lot #{index + 1}</h3>
                    {lots.length > 1 && (
                      <button
                        onClick={() => removeLot(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <BiTrash size={20} />
                      </button>
                    )}
                  </div>
                  <div className="space-y-4 w-full">
                    <SelectWithFullBorder
                      label={"Select Lot"}
                      selectOptions={getAvailableLots(index)}
                      value={lot.lot}
                      onChange={(e) => updateLot(index, "lot", e.target.value)}
                      placeholder="Select Lot"
                      required
                    />
                    <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                      {lot.is_buy_now ? (
                        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                          <InputWithFullBoarder
                            label={"Closing Amount"}
                            placeholder={"Enter Closing Amount"}
                            type={"number"}
                            value={lot.closing_amount}
                            onChange={(e) =>
                              updateLot(index, "closing_amount", e.target.value)
                            }
                            required
                          />
                        </div>
                      ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="flex-1">
                            <InputWithFullBoarder
                              label={"Start Amount"}
                              placeholder={"Enter Start Amount"}
                              type={"number"}
                              value={lot.starting_amount}
                              onChange={(e) =>
                                updateLot(
                                  index,
                                  "starting_amount",
                                  e.target.value
                                )
                              }
                              required
                            />
                            <InputWithFullBoarder
                              label={"Reserve Amount"}
                              placeholder={"Enter Reserve Amount"}
                              type={"number"}
                              value={lot.reserve_amount || 0}
                              onChange={(e) =>
                                updateLot(
                                  index,
                                  "reserve_amount",
                                  e.target.value
                                )
                              }
                              disabled={eventType === "OFFER"}
                              required
                            />
                          </div>
                          <div className="flex-1">
                            <InputWithFullBoarder
                              label={"Incremental Amount"}
                              placeholder={"Enter Incremental Amount"}
                              type={"number"}
                              value={lot.incremental_amount}
                              onChange={(e) =>
                                updateLot(
                                  index,
                                  "incremental_amount",
                                  e.target.value
                                )
                              }
                              required
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={`flex justify-between`}>
                    <CustomToggle
                      label="Buy Now"
                      enabled={lot.is_buy_now}
                      onChange={(value) =>
                        updateLot(index, "is_buy_now", value)
                      }
                    />
                    <ButtonWithIcon
                      buttonText="Add Another Lot"
                      onClick={addLot}
                      variant="outline"
                      icon={BiPlusCircle}
                      textColor={"#368618"}
                      className={"text-brandGreen bg-transparent"}
                      disabled={lots.length === Lots?.data?.results?.length}
                    />
                  </div>
                </div>
              ))}
              <div className="mt-4"></div>
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-2 mt-4">
          <CustomButton
            buttonText={"Cancel"}
            textColor={"#344054"}
            className="bg-white border border-gray-600"
            onClick={() => {
              navigate("/auction");
            }}
          />
          <CustomButton
            buttonText={auctionEvent ? "Update Event" : "Create Event"}
            onClick={handleCreateAuction}
            isLoading={isLoading}
            className={"w-full max-w-max"}
          />{" "}
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default CreateAuction;
