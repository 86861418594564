import React from "react";
import { thumbtack } from "../../../assets/icons";

const WhyEMMS = () => {
  const cardContent = [
    {
      cardIcon: thumbtack,
      cardTitle: "Efficiency Redefined",
      cardDescription:
        "Streamline your operations and minimize downtime with e-MMS. Our solution is designed to enhance efficiency at every step of the inventory management process.",
    },
    {
      cardIcon: thumbtack,
      cardTitle: "Collaborative Network",
      cardDescription:
        "Join a collaborative network of operators, fostering a culture of resource-sharing and mutual benefit within the oil and gas sector.",
    },
    {
      cardIcon: thumbtack,
      cardTitle: "Compliance and Sustainability",
      cardDescription:
        "e-MMS ensures that your inventory processes adhere to regulatory standards and contribute to sustainable practices in the industry",
    },
  ];

  return (
    <div className="my-10 py-16 w-full items-start justify-center  bg-[#F8FDF6]">
      {/* Section Header */}
      <p className="font-clash font-medium text-[14px] text-center text-brandGreen mb-2">
        Why e-MMS
      </p>
      <h3 className="font-clash font-medium text-[48px] text-center text-blackColor mb-2">
        The Best Material Management <br /> Solution for You
      </h3>

      {/* Card Container */}
      <div className="flex flex-col md:flex-row items-center justify-center gap-8 my-8">
        {cardContent.map((item, index) => (
          <div
            key={index}
            className="relative rounded-[5px] max-w-[383px] w-full h-[290px] bg-whiteColor flex flex-col items-start justify-start gap-4 py-6 px-8 border border-[#DBE2E8]"
          >
            {/* Icon at Top Left */}
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-brandGreen">
              <img src={item.cardIcon} alt="Item Icon" className="w-8 h-8" />
            </div>

            {/* Headline */}
            <h3 className="font-clash font-medium text-[22px]">
              {item.cardTitle}
            </h3>

            {/* Description */}
            <p className="font-instrument font-regular text-16px">
              {item.cardDescription}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyEMMS;
