import React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import useGetOperatorChartsManager from "../controller/GetOperatorChartsController";

const OperatorCharts = ({ startDate, endDate }) => {
  const { data: operatorCharts } = useGetOperatorChartsManager({
    start_date: startDate,
    end_date: endDate,
  });

  // Default data in case API response is undefined
  const data = [
    {
      name: "Project Items",
      value: operatorCharts?.total_project_items_count || 0,
      color: "#ED6634",
    },
    {
      name: "Stock Items",
      value: operatorCharts?.total_stock_items_count || 0,
      color: "#358619",
    },
    {
      name: "Borrowed Items",
      value: operatorCharts?.total_borrowed_items_count || 0,
      color: "#FDD2C2",
    },
  ];

  const totalValue = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <div>
      <div className="w-full bg-[#F7F8F9] items-center justify-center p-4 rounded-t-lg border border-gray-200">
        <h3 className="text-lg font-semibold">Inventory Statistics</h3>
      </div>
      <div className="h-[380px] bg-white rounded-b-lg p-6 border border-gray-200 ">
        <div className="flex flex-col items-center">
          <div className="relative w-64 h-64">
            <PieChart width={256} height={256}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={120}
                dataKey="value"
                startAngle={90}
                endAngle={-270}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="text-2xl font-bold">
                {totalValue.toLocaleString()}
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-12 mt-6 w-full justify-between">
            {data.map((item, index) => (
              <div key={index}>
                <div className="flex items-center gap-2 ">
                  <div
                    className="w-6 h-6 flex-shrink-0"
                    style={{ backgroundColor: item.color }}
                  ></div>
                  <p className="text-12px text-[#090909] my-2">{item.name}</p>
                </div>
                <p className="text-14px text-[#090909]">
                  {item.value.toLocaleString()} (
                  {totalValue
                    ? ((item.value / totalValue) * 100).toFixed(1)
                    : 0}
                  %)
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperatorCharts;
