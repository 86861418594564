import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const ScheduleInspectionManager = (id, type) => {
  // Dynamically construct the endpoint based on the type
  const endpoint =
    type === "borrow"
      ? `/stock/borrows/${id}/inspections/`
      : type === "return"
      ? `/stock/returns/${id}/inspections/`
      : type === "exchange"
      ? `/stock/exchange/${id}/inspections/`
      : type === "sales"
      ? `/stock/sales/${id}/inspections/`
      : null;

  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    endpoint,
    ["inspections", id],
    true
  );

  const scheduleInspection = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };

  return {
    scheduleInspection,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
