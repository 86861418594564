import useFormDataPatchManager from "../../../constants/controller_templates/patch_formdata_controller_template";

export const PurchaseSalesManager = (id) => {
  const { patchCaller, isLoading, isSuccess, error, data } =
    useFormDataPatchManager(
      `/stock/sales/${id}/purchase/`,
      ["sales"],
      false,
      true
    );
  const purchaseSales = async (details) => {
    try {
      await patchCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    purchaseSales,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
