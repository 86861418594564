import React, { useState } from "react";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { useNavigate } from "react-router-dom";
import StatusButton from "../../../generalComponents/StatusButton";
import useGetSalesRequestsManager from "../controllers/getSalesRequestController";
import SearchField from "../../../generalComponents/SearchField";
import useDebounce from "../../../utils/UseDebounce";
import { CompleteSalesManager } from "../controllers/completeSalesController";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import PurchaseModal from "./PurchaseModal";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import { hasPermissions } from "../../../constants/permissions";
import { BiCheck } from "react-icons/bi";
import { CgEye } from "react-icons/cg";
import { formatNumber } from "../../../utils/formatNumber";

const OutgoingSalesRequests = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [stockId, setStockId] = useState("");
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const { data, isLoading } = useGetSalesRequestsManager({
    is_buyer_client: true,
    enabled: true,
    page: currentPage,
    searchQuery: debouncedSearchValue,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const { completeSales } = CompleteSalesManager({ id: stockId });

  const headers = [
    "Asset Name",
    "Quantity",
    "Price",
    "Warehouse",
    "Operator",
    "Status",
    "Request Date",
    "Action",
  ];

  // const options = [
  //   {
  //     label: "View Request",
  //     permissions: ["view_stocksale"],
  //   },
  //   {
  //     label: "Purchase",
  //     permissions: ["view_stocksale"],
  //   },
  //   {
  //     label: "Complete Purchase",
  //     permissions: ["view_stocksale"],
  //   },
  // ];

  const getFormattedValue = (el, index) => {
    return [
      el?.stock,
      el?.quantity,
      formatNumber(el?.price) || "--",
      el?.warehouse,
      el?.client,
      <span>
        <StatusButton status={el?.status} />
      </span>,
      <span>{new Date(el?.created_datetime).toLocaleDateString()}</span>,
      <div className="flex gap-2">
        <div
          className={`${
            hasPermissions(["view_stocksale"])
              ? "flex space-x-[10px] mt-3 md:mt-0"
              : "hidden"
          } `}
        >
          <ButtonWithIcon
            buttonText="View"
            icon={CgEye}
            buttonColor="transparent"
            textColor="text-brandGreen"
            onClick={() => {
              navigate(`/marketplace/sales/${el.id}`, {
                state: { fromOutgoingRequests: true },
              });
            }}
            className="text-[8px] border border-brandGreen text-brandGreen my-2"
          />
          {el?.status === "approved" && (
            <ButtonWithIcon
              buttonText="Pay"
              icon={BiCheck}
              buttonColor="transparent"
              textColor="text-brandGreen"
              onClick={() => {
                setStockId(el);
                document.getElementById("purchase_asset").showModal();
              }}
              className="text-[8px] border border-brandGreen text-brandGreen my-2"
            />
          )}
          {el?.status === "purchased" && (
            <ButtonWithIcon
              buttonText="Complete"
              icon={BiCheck}
              buttonColor="transparent"
              textColor="text-brandGreen"
              onClick={() => {
                setStockId(el?.id);
                setTimeout(() => handleComplete(el?.id), 0);
              }}
              className="text-[8px] border border-brandGreen text-brandGreen my-2"
            />
          )}
        </div>
      </div>,
    ];
  };

  const handleComplete = (id) => {
    setStockId(id);
    completeSales({ id });
  };

  return (
    <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center gap-3">
          <SearchField
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />
        </div>
      </div>
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            popUpFunction={(option, index, val) => {
              console.log(option, index, val);
              setStockId(val.id);
              if (index === 0) {
                navigate(`/marketplace/sales/${val.id}`, {
                  state: { fromOutgoingRequests: true },
                });
              }

              if (index === 1) {
                setStockId(val);
                document.getElementById("purchase_asset").showModal();
              }
              // if (index === 2) {
              //   setStockId(val.id);
              //   handleApproval();
              // }
              if (index === 2) {
                setStockId(val.id);
                handleComplete(val.id);
              }
            }}
            // options={options}
            hideActionButton
            showCheckBox={false}
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
      <PurchaseModal
        stockId={stockId?.id}
        warehouse={stockId?.warehouse}
        assetName={stockId?.stock}
        quantity={stockId?.quantity}
      />
    </div>
  );
};
export default OutgoingSalesRequests;
