import React, { useMemo } from "react";
import { XAxis, CartesianGrid, Tooltip, BarChart, Bar } from "recharts";
import useGetOperatorStatsManager from "../controller/GetOperatorStatsController";
import Loader from "../../../generalComponents/Loader";

const OperatorStatistics = ({ startDate, endDate, currentView }) => {
  const type = useMemo(() => {
    switch (currentView) {
      case 0:
        return "inventory";
      case 1:
        return "disposal";
      case 2:
        return "market";
      case 3:
        return "exchange";
      case 4:
        return "borrow";
      default:
        return "inventory";
    }
  }, [currentView]);

  const { data: operatorStats, isLoading } = useGetOperatorStatsManager({
    start_date: startDate,
    end_date: endDate,
    type: type,
  });

  const chartData = operatorStats?.charts || [];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="w-full bg-[#F7F8F9] items-center justify-center p-4 rounded-t-lg border border-gray-200">
        <h3 className="text-lg font-semibold">Operators Statistics</h3>
      </div>
      <div className="bg-white p-6 rounded-b-lg w-full border border-gray-200">
        <BarChart width={1200} height={400} data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="client_name"
            name="Client"
            tick={{ fontSize: 12 }}
            axisLine={false}
            angle={-45}
            textAnchor="end"
            interval={0} // Ensures all labels are shown
            height={80} // Increases height to give space for labels
          />

          <Tooltip />
          <Bar
            dataKey="total_items_count"
            fill="#358619"
            radius={[4, 4, 0, 0]}
            label={{
              position: "top",
              fill: "#666",
              fontSize: 14,
            }}
            barSize={60}
            name={"Total Items"}
          />
        </BarChart>
      </div>
    </div>
  );
};

export default OperatorStatistics;
