import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import { useLocation, useParams } from "react-router-dom";
import { getLastPathSegment } from "../../../utils/getLastPath";
import Breadcrumb from "../../../generalComponents/BreadCrumb";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import { noImage } from "../../../assets/images";
// import { ApproveBorrowManager } from "../../borrow/controllers/approveBorrowController";
// import { RejectBorrowManager } from "../../borrow/controllers/rejectBorrowController";
import useGetConversationByIdManager from "../../borrow/controllers/getConversationByIdController";
import {
  // BiCalendar,
  // BiCheckCircle,
  BiSend,
} from "react-icons/bi";
// import { CgDanger } from "react-icons/cg";
import ScheduleModal from "./ScheduleModal";
import useGetUserDetailsManager from "../../settings/controllers/get_UserDetails_controller";
import { CreateConversationManager } from "../../borrow/controllers/createConversationController";
import useGetRequestById from "../controllers/getRequestById";
import { GrAttachment } from "react-icons/gr";

const RequestChatPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const lastSegment = getLastPathSegment(location.pathname);
  const type = location.state?.type;

  // eslint-disable-next-line
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [attachment, setAttachment] = useState(null);

  const { data: itemDetails } = useGetRequestById(type, id);

  // const { approveBorrow, isLoading: isApprovalLoading } = ApproveBorrowManager({
  //   id,
  // });
  // const { rejectBorrow, isLoading: isRejectionLoading } = RejectBorrowManager({
  //   id,
  // });

  const { data: staffProfile } = useGetUserDetailsManager();

  const item = itemDetails?.data?.stock;
  const conversationId = itemDetails?.data?.conversation?.id;

  const { createConversation, isLoading: isSending } =
    CreateConversationManager(conversationId);
  const { data: conversationDetails } =
    useGetConversationByIdManager(conversationId);
  // const approvalStatus = itemDetails?.data?.status;

  const currentUser = conversationDetails?.data?.results?.sender;

  useEffect(() => {
    if (conversationDetails?.data?.results) {
      const formattedMessages = conversationDetails.data.results.map(
        (message) => ({
          id: message.id,
          user: message?.sender?.client?.name,
          text: message.body,
          timestamp: new Date(message.created_datetime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          sender: message.sender === currentUser,
        })
      );
      setMessages(formattedMessages);
    }
  }, [conversationDetails, currentUser, setMessages]);

  const handleSendMessage = () => {
    if (newMessage.trim() || attachment) {
      const message = {
        body: newMessage,
        attachment: attachment,
      };
      createConversation(message);
      setNewMessage("");
      setAttachment(null);
    }
  };

  const items = [
    // { name: "Borrow", path: "/borrow/" },
    // { name: item?.asset_name, path: `/borrow/request/${lastSegment}` },
    { name: `${item?.asset_name} Chat`, path: `/requests/chat/${lastSegment}` },
  ];

  // const handleApproval = () => approveBorrow({ id });
  // const handleRejection = () => rejectBorrow({ id });

  return (
    <BaseDashboardNavigation>
      <div className="md:mt-4 flex flex-col md:flex-row mt-2 justify-between gap-y-5">
        <Breadcrumb items={items} />
      </div>
      <div className="flex flex-col h-[80vh] bg-gray-100 mt-5">
        <div className="bg-white p-4 shadow-sm">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <img
                src={item?.image || noImage}
                alt={item?.asset_name}
                className="w-10 h-10 rounded-md"
              />
              <span className="font-semibold text-lg">{item?.asset_name}</span>
            </div>
            {/* <div className="flex justify-end space-x-2 mt-4">
              {approvalStatus === "approved" ? (
                <ButtonWithIcon
                  buttonText="Schedule Inspection"
                  className="max-w-max bg-white border border-brandGreen text-brandGreen"
                  textColor={"brandGreen"}
                  icon={BiCalendar}
                  onClick={() => {
                    document.getElementById("schedule_inspection").showModal();
                  }}
                />
              ) : (
                ""
              )}
              {approvalStatus === "pending" ? (
                <>
                  <ButtonWithIcon
                    buttonText="Reject"
                    className="max-w-max bg-red-500 border"
                    icon={CgDanger}
                    isLoading={isRejectionLoading}
                    onClick={handleRejection}
                  />
                  <ButtonWithIcon
                    buttonText="Approve"
                    className="max-w-max border border-brandGreen text-brandGreen"
                    icon={BiCheckCircle}
                    isLoading={isApprovalLoading}
                    onClick={handleApproval}
                  />
                </>
              ) : (
                ""
              )}
            </div> */}
          </div>
        </div>

        {/* Message List */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {conversationDetails?.data?.results.map((message) => (
            <div
              key={message.id}
              className={`flex ${
                message.sender.user.id === staffProfile?.data?.user?.id
                  ? "justify-end"
                  : "justify-start"
              }`}
            >
              <div
                className={`max-w-[70%] ${
                  message.sender.user.id === staffProfile?.data?.user?.id
                    ? "bg-[#36861A] text-white"
                    : "bg-white"
                } rounded-md p-3 shadow-sm`}
              >
                <div className="flex items-baseline space-x-2">
                  <span
                    className={`font-medium text-14px ${
                      message.sender.user.id === staffProfile?.data?.user?.id
                        ? "text-white"
                        : "text-gray-500"
                    }`}
                  >
                    {message.sender?.user?.first_name}{" "}
                    {message.sender?.user?.last_name} -{" "}
                    {message.sender?.client?.name}
                  </span>
                  <span
                    className={`text-xs ${
                      message.sender.user.id === staffProfile?.data?.user?.id
                        ? "text-gray-200"
                        : "text-gray-500"
                    }`}
                  >
                    {new Date(message.created_datetime).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
                <p
                  className={`mt-1 text-16px ${
                    message.sender.user.id === staffProfile?.data?.user?.id
                      ? "text-white"
                      : "text-gray-800"
                  }`}
                >
                  {message.body}
                </p>
                {message.attachment && (
                  <a
                    href={message.attachment}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`mt-2 inline-flex items-center px-3 py-1 space-x-2 rounded-full ${
                      message.sender.user.id === staffProfile?.data?.user?.id
                        ? "bg-white/20 text-white hover:bg-white/30"
                        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                    } transition-colors`}
                  >
                    <GrAttachment
                      className={
                        message.sender.user.id === staffProfile?.data?.user?.id
                          ? "filter invert"
                          : ""
                      }
                    />
                    <span className="text-sm">View Attachment</span>
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Message Input */}
        <div className="p-4 bg-white border-t">
          <div className="flex flex-col space-y-2">
            {attachment && (
              <div className="flex items-center space-x-2 p-2 bg-gray-50 rounded">
                <span className="text-sm">{attachment.name}</span>
                <button
                  onClick={() => setAttachment(null)}
                  className="text-red-500 text-sm"
                >
                  Remove
                </button>
              </div>
            )}
            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
                placeholder="Type a message..."
                className="flex-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-brandGreen"
              />
              <label className="cursor-pointer">
                <input
                  type="file"
                  className="hidden"
                  onChange={(e) => setAttachment(e.target.files[0])}
                />
                <span className="rounded-full bg-gray-200 hover:bg-gray-300">
                  <GrAttachment />
                </span>
              </label>
              <ButtonWithIcon
                buttonText="Send"
                icon={BiSend}
                onClick={handleSendMessage}
                isLoading={isSending}
                className="rounded-full bg-[#36861A] text-white p-2 hover:bg-[#2a6814] focus:outline-none focus:ring-2 focus:ring-[#36861A]"
              />
            </div>
          </div>
        </div>
      </div>
      <ScheduleModal stockId={id} type={"borrow"} />
    </BaseDashboardNavigation>
  );
};

export default RequestChatPage;
