import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { GrFormNext } from "react-icons/gr";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import ModalManagement from "../../../generalComponents/ModalManagement";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import { RescheduleInspectionManager } from "../controllers/rescheduleInspectionController";

const RescheduleModal = ({ inspectionId, assignedStaff }) => {
  const [rescheduleDate, setReScheduleDate] = useState("");

  const { rescheduleInspection, isSuccess } =
    RescheduleInspectionManager(inspectionId);

  const handleSchedule = () => {
    const schedule = {
      scheduled_date: rescheduleDate,
    };

    rescheduleInspection(schedule);
  };

  const clearFields = () => {
    setReScheduleDate("");
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("reschedule_inspection").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement
        id={"reschedule_inspection"}
        title={"Reschedule Inspection"}
        onClose={clearFields}
      >
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2 ">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Inspection Date"}
                placeholder={"Inspection Date"}
                type={"date"}
                value={rescheduleDate}
                onChange={(e) => {
                  console.log("Date changed:", e.target.value);
                  setReScheduleDate(e.target.value);
                }}
              />
            </div>
          </div>
          {assignedStaff && (
            <div className="flex flex-col md:flex-row md:space-x-2 ">
              <div className="flex-1">
                <InputWithFullBoarder
                  label={"Assigned Staff"}
                  value={assignedStaff}
                  disabled
                />
              </div>
            </div>
          )}

          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              icon={MdCancel}
              buttonText={"Cancel"}
              textColor={"#ff0000"}
              className={"bg-red-500 border w-auto text-white"}
              onClick={() => {
                document.getElementById("reschedule_inspection").close();
                clearFields();
              }}
            />
            <ButtonWithIcon
              icon={GrFormNext}
              buttonText={"Reschedule"}
              onClick={handleSchedule}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default RescheduleModal;
