import React, { useState } from "react";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { useNavigate } from "react-router-dom";
import StatusButton from "../../../generalComponents/StatusButton";
import useGetExchangeRequestsManager from "../controllers/getExchangeRequestsController";
import SearchField from "../../../generalComponents/SearchField";
import useDebounce from "../../../utils/UseDebounce";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import { ApproveExchangeManager } from "../controllers/approveExchangeController";
import { RejectExchangeManager } from "../controllers/rejectExchangeController";
import { CompleteExchangeManager } from "../controllers/completeExchangeController";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import { BiCheck, BiX } from "react-icons/bi";
import { CgEye } from "react-icons/cg";
import { hasPermissions } from "../../../constants/permissions";

const IncomingExchange = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [stockId, setStockId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&stock_name=${searchValue}`, 1000);
  const navigate = useNavigate();

  const { data, isLoading } = useGetExchangeRequestsManager({
    is_seller_client: true,
    enabled: true,
    page: currentPage,
    searchQuery: debouncedSearchValue,
  });
  const { approveExchange } = ApproveExchangeManager(stockId);

  const { rejectExchange } = RejectExchangeManager(stockId);

  const { completeExchange } = CompleteExchangeManager(stockId);

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "S/N",
    "Asset Name",
    "Asset Quantity",
    "Exchange Asset",
    "Exchange Quantity",
    "Operator",
    "Status",
    "Request Date",
    "Action",
  ];

  // const options = [
  //   {
  //     label: "View Request",
  //     permissions: ["view_stockexchange"],
  //   },
  //   {
  //     label: "Accept Request",
  //     permissions: ["accept_stockexchange"],
  //   },
  //   {
  //     label: "Reject Request",
  //     permissions: ["reject_stockexchange"],
  //   },
  //   { label: "Complete Exchange", permissions: ["complete_stockexchange"] },
  // ];

  const getFormattedValue = (el, index) => {
    return [
      index + 1,
      el?.stock,
      el?.quantity,
      el?.exchange_stock,
      el?.exchange_stock_quantity,
      el?.client,
      <span>
        <StatusButton status={el?.status} />
      </span>,
      <span>{new Date(el?.created_datetime).toLocaleDateString()}</span>,
      <div className="flex gap-2">
        <ButtonWithIcon
          buttonText="View"
          icon={CgEye}
          buttonColor="transparent"
          textColor="text-brandGreen"
          onClick={() => {
            navigate(`/marketplace/exchange/${el?.id}`, {
              state: { fromIncomingRequests: true },
            });
          }}
          className="text-[8px] border border-brandGreen text-brandGreen my-2"
        />
        <div
          className={`${
            hasPermissions([
              "accept_stockexchange",
              "reject_stockexchange",
              "complete_stockexchange",
            ])
              ? "flex space-x-[10px] mt-3 md:mt-0"
              : "hidden"
          } `}
        >
          {el?.status === "pending" && (
            <>
              <ButtonWithIcon
                buttonText="Accept"
                icon={BiCheck}
                buttonColor="transparent"
                textColor="text-brandGreen"
                onClick={() => {
                  setStockId(el?.id);
                  setTimeout(() => handleApproval(el?.id), 0);
                }}
                className="text-[8px] border border-brandGreen text-brandGreen my-2 w-min"
              />
              <ButtonWithIcon
                buttonText="Reject"
                icon={BiX}
                buttonColor="transparent"
                textColor="text-red-500"
                onClick={() => {
                  setStockId(el?.id);
                  setTimeout(() => handleRejection(el?.id), 0);
                }}
                className="text-[8px] border border-red-500 text-red-500 my-2"
              />
            </>
          )}
          {el?.status === "approved" && (
            <ButtonWithIcon
              buttonText="Complete"
              icon={BiCheck}
              buttonColor="transparent"
              textColor="text-brandGreen"
              onClick={() => {
                setStockId(el?.id);
                setTimeout(() => handleComplete(el?.id), 0);
              }}
              className="text-[8px] border border-brandGreen text-brandGreen my-2 w-min"
            />
          )}
        </div>
      </div>,
    ];
  };

  const handleApproval = (id) => {
    setStockId(id);
    approveExchange({ id });
  };

  const handleRejection = (id) => {
    setStockId(id);
    rejectExchange({ id });
  };

  const handleComplete = (id) => {
    setStockId(id);
    completeExchange({ id });
  };

  return (
    <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center gap-3">
          <SearchField
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />
        </div>
      </div>
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            // buttonFunction={(val) => {
            //   navigate(`/borrow/request/${val.id}`);
            //   console.log(val);
            // }}
            popUpFunction={(option, index, val) => {
              console.log(option, index, val);
              if (index === 0) {
                navigate(`/marketplace/exchange/${val.id}`, {
                  state: { fromIncomingRequests: true },
                });
              }
              if (index === 1) {
                setStockId(val.id);
                setTimeout(() => handleApproval(val.id), 0);
              }
              if (index === 2) {
                setStockId(val.id);
                setTimeout(() => handleRejection(val.id), 0);
              }
              if (index === 3) {
                setStockId(val.id);
                setTimeout(() => handleComplete(val.id), 0);
              }
            }}
            hideActionButton
            // options={options}
            // Close popup function
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};
export default IncomingExchange;
