import React from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import CustomButton from "../../../generalComponents/Button";
import { formatNumber } from "../../../utils/formatNumber";

const RemitaConfirmationModal = ({
  totalAmount,
  remitaRRR,
  transactionId,
  onPayNow,
  isLoading,
}) => {
  return (
    <ModalManagement id="remita_confirmation_modal">
      <div className="md:w-[500px] h-auto rounded-[6px] relative bg-white flex flex-col pt-2">
        <p className="text-xl font-medium text-center p-6">Payment Details</p>

        <div className="px-6 pb-4">
          <div className="mb-4 p-4 bg-gray-100 rounded-md">
            <div className="flex justify-between mb-2">
              <span className="font-medium">Amount:</span>
              <span>₦ {formatNumber(totalAmount)}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="font-medium">RRR:</span>
              <span>{remitaRRR || "Generating..."}</span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium">Transaction ID:</span>
              <span>{transactionId || "Generating..."}</span>
            </div>
          </div>

          <p className="text-sm text-gray-600 mb-4 text-center">
            Click "Pay Now" to proceed to Remita secure payment page
          </p>

          <div className="flex justify-center">
            <CustomButton
              buttonText={isLoading ? "Processing..." : "Pay Now"}
              onClick={onPayNow}
              disabled={isLoading || !remitaRRR || !transactionId}
              className="w-full"
            />
          </div>
        </div>
      </div>
    </ModalManagement>
  );
};

export default RemitaConfirmationModal;
