import useGetInventoryOverviewManager from "../controller/GetInventoryOverviewController";
import { formatNumber } from "../../../utils/formatNumber";
import Loader from "../../../generalComponents/Loader";

const InventoryOverview = ({ startDate, endDate, operatorId }) => {
  const { data: inventoryOverview, isLoading } = useGetInventoryOverviewManager(
    { client: operatorId, start_date: startDate, end_date: endDate }
  );

  return (
    <div className="flex flex-col items-end justify-normal w-full relative">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-3 gap-6 mb-8 w-full">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Stock Items:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(inventoryOverview?.total_stock_items_count) || 0}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Project Items:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(inventoryOverview?.total_project_items_count) || 0}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 mb-2">Market Items:</h3>
            <p className="text-3xl font-semibold">
              {formatNumber(inventoryOverview?.total_market_items_count) || 0}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default InventoryOverview;
