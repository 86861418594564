import React, { useEffect, useState } from "react";
import GoBackButton from "../../../generalComponents/GoBackButton";
import { noImage } from "../../../assets/images";
import StatusButton from "../../../generalComponents/StatusButton";
import CustomButton from "../../../generalComponents/Button";
import HeaderFooter from "../../../generalComponents/HeaderFooter";
import useGetLotById from "../../lots/controllers/getLotByIdController";
import { useLocation, useParams } from "react-router-dom";
import useGetBidByLotId from "../components/controllers/getBidByLotIdController";
import { PostBidsManager } from "../components/controllers/postBidController";
import Countdown from "../../../generalComponents/CountDown";
import { formatDateAndTime } from "../../../utils/formatDateAndTime";
import { formatDateTimeAndSeconds } from "../../../utils/formatDateTimeAndSeconds";
import {
  firstIcon,
  noBids,
  secondIcon,
  thirdIcon,
} from "../../../assets/icons";
import { formatNumber } from "../../../utils/formatNumber";
import useGetAuctionParticipants from "../components/controllers/getAuctionParticipantsController";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import { BidComplete, BidWinner } from "../components/BidModals";
import useGetUserDetailsManager from "../../settings/controllers/get_UserDetails_controller";
import ReactConfetti from "react-confetti";

const BidPage = () => {
  const { id } = useParams();
  const { eventId } = useLocation().state;
  const { data, refetch: refetchLot, isLoading } = useGetLotById({ id });
  const [showConfetti, setShowConfetti] = useState(false);

  const { postBid, isSuccess } = PostBidsManager();
  const { data: participants } = useGetAuctionParticipants(eventId);

  const { data: profile } = useGetUserDetailsManager();

  const highestBid = data?.data?.highest_bid_amount;
  const startingAmount = data?.data?.starting_amount;
  const acutionType = data?.data?.auction?.event_type;

  const minimumBid =
    Number(highestBid) === 0
      ? data?.data?.starting_amount
      : Number(highestBid) + Number(data?.data?.incremental_amount);

  const [amount, setAmount] = useState("");

  // Update amount whenever minimumBid changes
  // Only set minimum bid for "BID" type
  useEffect(() => {
    if (acutionType === "BID") {
      const minBid =
        Number(highestBid) === 0
          ? Number(startingAmount)
          : Number(highestBid) + Number(data?.data?.incremental_amount);
      setAmount(minBid);
    }
  }, [highestBid, startingAmount, data?.data?.incremental_amount, acutionType]);

  const handleIncrement = () => {
    if (data?.data?.incremental_amount) {
      setAmount((prev) => Number(prev) + Number(data.data.incremental_amount));
    }
  };

  const handleDecrement = () => {
    if (data?.data?.incremental_amount) {
      setAmount((prev) =>
        Number(prev) >= Number(data.data.incremental_amount)
          ? Number(prev) - Number(data.data.incremental_amount)
          : 0
      );
    }
  };

  const { data: bids, refetch } = useGetBidByLotId({
    id,
    participant: acutionType === "OFFER",
  });

  const lotItems = data?.data?.lot_items;

  const bidHistory = bids?.data?.results;

  const isBuyNow = data?.data?.is_buy_now;

  const isEnded = data?.data?.auction?.is_ended;

  const clientId = profile?.data?.client?.id;

  const highestBidderId = data?.data?.highest_bidder_client?.id;

  const isHighestBidder = clientId === highestBidderId;

  const isPremium = parseFloat(data?.data?.auction?.entry_fee || 0) > 1;

  const handlePlaceBid = async () => {
    const bid = {
      amount: amount,
      lot: id,
    };
    await postBid(bid);
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
      refetchLot();
    }
  }, [isSuccess, refetch, refetchLot]);

  useEffect(() => {
    if (isEnded && isHighestBidder) {
      document.getElementById("winner_modal").showModal();
      setShowConfetti(true);
      const timer = setTimeout(() => setShowConfetti(false), 5000);
      return () => clearTimeout(timer);
    } else if (isEnded && !isHighestBidder) {
      document.getElementById("bid_complete").showModal();
    }
  }, [isEnded, isHighestBidder]);

  return (
    <HeaderFooter>
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={800} // Adjust the number of pieces as needed
        />
      )}
      <div
        className={`max-w-[1240px] w-full flex flex-col text-blackColor mb-4  md:mx-auto mx-4 relative`}
      >
        {/* Header Section */}
        <div
          className={`justify-between items-center flex flex-col mt-10 mb-2 w-full`}
        >
          <GoBackButton />
          <h3 className="text-3xl font-semibold w-full text-left leading-tight mt-4">
            {data?.data?.name}
          </h3>
        </div>{" "}
        {/* Header Section */}
        <div className="flex flex-col gap-8 mt-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mx-auto w-full ">
            {/* Left Column - Lot Information */}
            <div className="bg-white p-0 rounded-lg border border-gray-200 h-fit max-h-[500px] overflow-y-auto">
              <div className="p-4 rounded-t-lg bg-[#F5F6F8]">
                <h2 className="text-20px font-semibold mb-0">Lot Details:</h2>
              </div>
              <div>
                {lotItems?.map((item, index) => (
                  <div
                    key={index}
                    className="flex p-4 hover:bg-gray-100 transition-colors items-center justify-between border bottom-1 py-6"
                  >
                    <div className="flex items-center">
                      <img
                        src={item?.image_url || noImage}
                        className="w-16"
                        alt="Item"
                      />
                      <span className="ml-4">
                        <p className="font-medium text-gray-600 text-16px">
                          {item.disposal_item}
                        </p>
                        <p className="text-sm text-gray-500">{item.details}</p>
                      </span>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center gap-1">
                        <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                        <p className="text-16px text-gray-600">
                          Quantity: {item.quantity}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Right Column - Auction Details */}
            <div className="bg-white p-0 rounded-lg border border-gray-200 h-fit">
              <div className="p-4 rounded-t-lg bg-[#F5F6F8] flex justify-between">
                <h2 className="text-20px font-semibold mb-0">
                  Auction Details:
                </h2>
                {data?.data?.starting_amount > 0 && (
                  <span className="bg-[#E7F5EC] text-12px p-2 text-black">
                    {acutionType === "OFFER"
                      ? "Minium Offer Amount"
                      : "Starting Price"}{" "}
                    ₦{data?.data?.starting_amount}
                  </span>
                )}
              </div>
              <div className="flex justify-between p-6 scale-100">
                <div className="text-3xl font-semibold text-brandGreen">
                  <Countdown
                    endDatetime={data?.data?.auction?.end_datetime}
                    isLoading={isLoading}
                  />
                </div>
                <StatusButton status={isEnded ? "Inactive" : "Active"} />
              </div>
              <div className="px-4 mb-4">
                <p className="text-sm text-gray-500">
                  Ending on:{" "}
                  <span className="text-red-500">
                    {formatDateAndTime(data?.data?.auction?.end_datetime)}
                  </span>
                </p>
              </div>
              <div className="p-8 bg-[#F1FCEE] mx-4 rounded-md my-8 text-center border border-[#D4F6CB]">
                <span className="">
                  {acutionType === "OFFER"
                    ? "Your Offer"
                    : isBuyNow
                    ? "Buy Now Price"
                    : "Current Highest Bid"}
                </span>
                {acutionType === "OFFER" ? (
                  <p className="font-bold text-[38px] py-4 text-brandGreen">
                    ₦{formatNumber(bids?.data?.results?.[0]?.amount) || 0}
                  </p>
                ) : (
                  <p className="font-bold text-[38px] py-4 text-brandGreen">
                    ₦
                    {formatNumber(
                      isBuyNow
                        ? data?.data?.closing_amount
                        : data?.data?.highest_bid_amount
                    ) || 0}
                  </p>
                )}
                {!isBuyNow && acutionType === "BID" && (
                  <div className="space-x-4 py-3">
                    <span className="">
                      Total Bidders: {data?.data?.total_users_bid_count}
                    </span>
                    <span className="">
                      Total Bids: {data?.data?.total_bid_count}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex flex-wrap justify-center gap-4 my-4 px-4">
                {isBuyNow && (
                  <CustomButton
                    buttonText={"Buy Now"}
                    className={"w-full"}
                    onClick={handlePlaceBid}
                    disabled={isEnded}
                  />
                )}
                {acutionType === "BID" && !isBuyNow && (
                  <div className="relative w-full">
                    <label className="absolute left-2 top-1 text-xs text-gray-500">
                      Enter your bid (Minimum {minimumBid})
                    </label>
                    <div className="flex items-stretch border border-gray-300 rounded">
                      <input
                        // type="number"
                        value={amount}
                        onChange={(e) => setAmount(Number(e.target.value))}
                        className="flex-grow pl-4 focus:outline-none rounded-md items-center text-xl"
                        placeholder="Enter amount"
                      />
                      <div className="flex flex-col justify-between h-full py-1">
                        <button
                          className="bg-[#F5F6F8] border border-gray-300 px-3 py-1 mr-2 mb-1 rounded-md"
                          onClick={handleIncrement}
                          type="button"
                        >
                          +
                        </button>
                        <button
                          className="bg-[#F5F6F8] border border-gray-300 px-3 py-1 mr-2 mt-1 rounded-md"
                          onClick={handleDecrement}
                          type="button"
                        >
                          -
                        </button>
                      </div>
                    </div>
                    <div className="w-full my-2 text-center">
                      <CustomButton
                        buttonText={"Place Bid"}
                        className={"w-full"}
                        onClick={handlePlaceBid}
                        disabled={
                          (isPremium &&
                            participants?.data?.status !== "CONFIRMED") ||
                          isEnded
                        }
                      />
                    </div>
                  </div>
                )}
                {acutionType === "OFFER" && (
                  <div className="w-full">
                    <InputWithFullBoarder
                      label={`Offer Amount (Minimum ${formatNumber(
                        startingAmount
                      )})`}
                      placeholder={"Enter Offer Amount"}
                      className={"h-[50px]"}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      disabled={
                        isEnded ||
                        (acutionType === "OFFER" &&
                          bids?.data?.results?.[0]?.amount > 0)
                      }
                    />
                    <CustomButton
                      buttonText={"Make Offer"}
                      className={"w-full"}
                      onClick={handlePlaceBid}
                      disabled={
                        isEnded ||
                        (acutionType === "OFFER" &&
                          bids?.data?.results?.[0]?.amount > 0)
                      }
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Right Column - Live Bid History */}
          </div>
          {/* Live Bid History Card */}
          {!isBuyNow && acutionType === "BID" && (
            <div className="bg-white p-0 rounded-lg border border-gray-200 h-fit mb-6 pb-6">
              <div className="p-4 rounded-t-lg bg-[#F5F6F8]">
                <h2 className="text-20px font-semibold mb-0">
                  Live Bid History
                </h2>
              </div>
              {bidHistory?.length > 0 ? (
                <div className="px-36 mt-4 mb-8">
                  <div className="overflow-y-auto max-h-[500px] rounded-xl">
                    <table className="w-full">
                      <thead className="bg-[#F5F6F8]">
                        <tr className="text-gray-600">
                          <th className="text-left p-4 rounded-tl-lg text-16px semi-bold">
                            Bidder
                          </th>
                          <th className="text-right p-4 text-16px semi-bold">
                            Amount
                          </th>
                          <th className="text-right p-4 rounded-tr-lg text-[16px] semi-bold">
                            Date-Time
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y">
                        {bidHistory?.map((bid, index) => (
                          <tr
                            key={index}
                            className={`
                        ${
                          index === 0
                            ? "bg-brandGreen text-white"
                            : "hover:bg-gray-50"
                        } 
                        transition-colors
                      `}
                          >
                            <td className="p-6 font-medium flex gap-2 items-center">
                              {index < 3 ? (
                                <img
                                  src={
                                    index === 0
                                      ? firstIcon
                                      : index === 1
                                      ? secondIcon
                                      : thirdIcon
                                  }
                                  alt={`${index + 1}st place`}
                                  className="w-10 h-10"
                                />
                              ) : (
                                <span className="w-5 text-gray-500 text-sm">
                                  {index + 1}
                                </span>
                              )}
                              <img
                                src={
                                  bid?.created_by?.profile_image_url || noImage
                                }
                                className="w-8 h-8 rounded-full object-cover aspect-square"
                                alt={"Profile"}
                              />
                              {bid?.created_by?.first_name +
                                " " +
                                bid?.created_by?.last_name}
                            </td>
                            <td className="text-right p-4 font-semibold">
                              {formatNumber(bid.amount)}
                            </td>
                            <td className="text-right p-4">
                              {formatDateTimeAndSeconds(bid?.created_datetime)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center py-12">
                  {/* You can import your SVG as an asset instead of using this inline version */}
                  <img
                    src={noBids}
                    alt="No bids yet"
                    className="w-48 h-48 mb-4"
                  />
                  <p className="text-gray-500 text-lg font-medium">
                    No bids yet
                  </p>
                </div>
              )}
            </div>
          )}

          {/* Bottom Grid for Lot Details and Bid History */}
        </div>
      </div>
      <BidComplete />
      <BidWinner />
    </HeaderFooter>
  );
};

export default BidPage;
