import React, { useState } from "react";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import { useLocation, useParams } from "react-router-dom";
import EditRequestIdModal from "../components/EditRequestIdModal";
import DisposalInspectionModal from "../components/DisposalInspectionModal";
import LetterUploadModal from "../components/LetterUploadModal";
import { noImage } from "../../../../assets/images";
import ApprovalIdModal from "../components/ApprovalIdModal";
import DocumentsUploadModal from "../components/DocumentsUploadModal";
import useGetDisposalInspectionManager from "../controllers/getDisposalInspectionController";
import { hasPermissions } from "../../../../constants/permissions";
import useGetOtherDocumentsManager from "../controllers/getOtherDocumentsController";
import { BiPlusCircle } from "react-icons/bi";
import { useClientType } from "../../../../constants/useClientTypes";
import Loader from "../../../../generalComponents/Loader";

const ItemsByRequestIdDetails = ({
  requestLetter,
  approvalId,
  approvalLetter,
}) => {
  const { request_id } = useParams();
  const location = useLocation();
  const itemId = location.state?.itemId;
  const [activeLetterType, setActiveLetterType] = useState("");
  const { data: disposalInspection, isLoading } =
    useGetDisposalInspectionManager(itemId);
  const { data: otherDocuments } = useGetOtherDocumentsManager(itemId);

  const inspectionDetails = disposalInspection?.data?.results;
  const { isOperator } = useClientType();

  return (
    <div>
      <div className="w-full flex flex-col gap-5"></div>
      <div className="mt-2 md:mt-6 w-full grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-white rounded-md border border-gray-300 p-4 flex flex-col justify-between items-center">
          <h4 className="text-lg font-semibold">Request Letter</h4>
          {requestLetter ? (
            <ButtonWithIcon
              buttonText="View Request Letter"
              className="bg-transparent text-brandGreen border border-brandGreen mt-4"
              textColor={"#358619"}
              radius={"md"}
              onClick={() => window.open(requestLetter, "_blank")}
            />
          ) : hasPermissions([
              "add_stockdisposal",
              "add_stockdisposaldocument",
            ]) ? (
            <ButtonWithIcon
              buttonText="Upload Request Letter"
              className="bg-transparent text-brandGreen border border-brandGreen mt-4"
              textColor={"#358619"}
              radius={"md"}
              onClick={() => {
                setActiveLetterType("request_letter");
                setTimeout(() => {
                  document.getElementById("request_letter").showModal();
                }, 0);
              }}
            />
          ) : (
            <p>Not Available</p>
          )}
        </div>
        <div className="bg-white rounded-md border border-gray-300 p-4 flex flex-col justify-between items-center ">
          <h4 className="text-lg font-semibold">Approval ID</h4>
          {approvalId ? (
            <p className="text-brandGreen font-bold">{approvalId}</p>
          ) : hasPermissions(["view_assetgroup"]) ? (
            <ButtonWithIcon
              buttonText={"Enter Approval ID"}
              className="bg-transparent text-brandGreen border border-brandGreen mt-4"
              textColor={"#358619"}
              radius={"md"}
              onClick={() => {
                document.getElementById("approval_id").showModal();
              }}
            />
          ) : (
            <p>Not Available</p>
          )}
        </div>

        <div className="bg-white rounded-md border border-gray-300 p-4 flex flex-col justify-between items-center">
          <h4 className="text-lg font-semibold">Approval Letter</h4>
          {approvalLetter ? (
            <ButtonWithIcon
              buttonText="View Approval Letter"
              className="bg-transparent text-brandGreen border border-brandGreen"
              textColor={"#358619"}
              radius={"md"}
              onClick={() => window.open(approvalLetter, "_blank")}
            />
          ) : hasPermissions(["view_assetgroup"]) ? (
            <ButtonWithIcon
              buttonText="Upload Approval Letter"
              className="bg-transparent text-brandGreen border border-brandGreen"
              textColor={"#358619"}
              radius={"md"}
              onClick={() => {
                setActiveLetterType("approval_letter");
                setTimeout(() => {
                  document.getElementById("approval_letter").showModal();
                }, 0);
              }}
            />
          ) : (
            <p>Not Available</p>
          )}
        </div>
      </div>
      <div className="mt-2 md:mt-6 w-full">
        <div className="rounded-md border border-gray-300 p-4">
          <h4 className="text-lg font-semibold mb-4">Other Documents</h4>

          <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
            {otherDocuments?.data?.results?.length > 0 ? (
              <>
                {otherDocuments.data.results.map((document) => (
                  <div
                    key={document.id}
                    className="flex flex-col bg-white justify-between items-center p-8 border border-gray-300 rounded-md"
                  >
                    <h4 className="text-lg font-semibold">{document?.title}</h4>
                    <ButtonWithIcon
                      buttonText={`View ${document?.title.replace(
                        /\.[^/.]+$/,
                        ""
                      )}`}
                      radius={"md"}
                      className="bg-transparent text-brandGreen border border-brandGreen mt-3"
                      textColor={"#358619"}
                      onClick={() =>
                        window.open(document?.document_url, "_blank")
                      }
                    />
                  </div>
                ))}
                {hasPermissions(["add_stockdisposaldocument"]) && (
                  <div
                    onClick={() => {
                      setActiveLetterType("other_documents");
                      setTimeout(() => {
                        document.getElementById("other_documents").showModal();
                      }, 0);
                    }}
                    className="flex flex-col justify-center items-center h-40 border border-dashed border-gray-400 rounded-md bg-[#EBFAE5] text-brandGreen cursor-pointer hover:bg-[#E0F5D9]"
                  >
                    <BiPlusCircle size={28} />
                    <span className="mt-2">Add Document</span>
                  </div>
                )}
              </>
            ) : hasPermissions([
                "add_stockdisposaldocument",
                "add_stockdisposal",
              ]) ? (
              <div
                onClick={() => {
                  setActiveLetterType("other_documents");
                  setTimeout(() => {
                    document.getElementById("other_documents").showModal();
                  }, 0);
                }}
                className="flex flex-col justify-center items-center h-40 border border-dashed border-gray-400 rounded-md bg-[#EBFAE5] text-brandGreen cursor-pointer hover:bg-[#E0F5D9]"
              >
                <BiPlusCircle size={28} />
                <span className="mt-2">Add Document</span>
              </div>
            ) : (
              <div className="col-span-5 text-center py-8">
                <p>No Documents Available</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {inspectionDetails?.length > 0 ? (
            <div>
              {inspectionDetails?.map((inspection) => (
                <div
                  className={`${
                    hasPermissions(["view_inspection"]) ? "py-2" : "hidden"
                  } `}
                >
                  {" "}
                  <div className="mt-2 md:mt-6 w-full grid grid-cols-1 md:grid-cols-2 gap-4 ">
                    <div className="bg-white border border-gray-300 rounded-md">
                      <div className="rounded-md  p-4 flex flex-col justify-between bg-[#F0F2F5]">
                        <h4 className="text-lg font-semibold">
                          Inspection Details
                        </h4>
                      </div>
                      <div className="p-4">
                        <p className="text-14px font-semibold">Title:</p>
                        <p className="text-14px">{inspection?.title}</p>
                      </div>
                      <div className="border border-gray-100" />
                      <div className="p-4">
                        <p className="text-14px font-semibold">Description:</p>
                        <p className="text-14px">{inspection?.description}</p>
                      </div>
                      <div className="border border-gray-100" />
                      <div className="p-4">
                        <p className="text-14px font-semibold">Date</p>
                        <p className="text-14px">
                          {inspection?.scheduled_date}
                        </p>
                      </div>
                      <div className="border border-gray-100" />
                      <div className="p-4">
                        <p className="text-14px font-semibold">Assignees</p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-2">
                          {inspectionDetails?.[0]?.assigned_staffs?.map(
                            (staff) => (
                              <div className="flex items-center gap-2">
                                <img
                                  src={
                                    staff?.user?.profile_image_url || noImage
                                  }
                                  width={30}
                                  height={30}
                                  className="rounded-full"
                                  alt="Profile"
                                />
                                <p className="text-14px font-medium">
                                  {staff?.user?.first_name}{" "}
                                  {staff?.user?.last_name}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="bg-white border border-gray-300 rounded-md">
                  <div className="rounded-md  p-4 flex flex-col justify-between bg-[#F0F2F5]">
                    <h4 className="text-lg font-semibold">Comments</h4>
                  </div>

                  <div className="border border-gray-200"></div>
                  <div className="p-4">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-2"></div>
                  </div>
                </div> */}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className={`${
                hasPermissions(["add_stockdisposaldocument"])
                  ? "py-2"
                  : "hidden"
              } `}
            >
              {!isOperator && (
                <div className="mt-2 md:mt-6 w-full grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className=" rounded-md border border-gray-300 p-4 flex flex-col justify-between items-center">
                    <h4 className="text-lg font-semibold">Create Inspection</h4>
                    <ButtonWithIcon
                      buttonText={"Create Inspection"}
                      className={"border border-brandGreen mt-4"}
                      radius={"md"}
                      onClick={() => {
                        document
                          .getElementById("schedule_inspection")
                          .showModal();
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <EditRequestIdModal request_id={request_id} itemId={itemId} />
      <LetterUploadModal itemId={itemId} letterType={activeLetterType} />
      <DisposalInspectionModal request_id={request_id} itemId={itemId} />
      <ApprovalIdModal itemId={itemId} />
      <DocumentsUploadModal />
    </div>
  );
};

export default ItemsByRequestIdDetails;
