import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetDisposalOverviewManager = ({
  client = "",
  start_date,
  end_date,
}) => {
  return useQuery(["disposals", client, start_date, end_date], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(
          `/disposal/overview/?client=${client}&start_date=${start_date}&end_date=${end_date}`
        ),
      ];
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetDisposalOverviewManager;
