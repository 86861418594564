import React, { useEffect, useState } from "react";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import { BiPlus, BiXCircle } from "react-icons/bi";
import CustomCheckBox from "../../../../generalComponents/CustomCheckBox";
import { CreateUtilizationManager } from "../controllers/createUtilizationController";

const UtilizationModal = ({ stock_id }) => {
  const [utilizationQuantity, setUtilizationQuantity] = useState();
  const [utilizationDate, setUtilizationDate] = useState("");
  const [remarks, setRemarks] = useState("");
  const [isConsumable, setIsConsumable] = useState(false);

  const { createUtilization, isSuccess, isLoading } =
    CreateUtilizationManager();

  const handleUtilization = () => {
    const payload = {
      stock_allocation: stock_id,
      quantity: utilizationQuantity,
      remark: remarks,
      utilization_date: utilizationDate,
      is_consumable: isConsumable,
    };

    createUtilization(payload);
  };

  const clearFields = () => {
    setUtilizationQuantity("");
    setUtilizationDate("");
    setRemarks("");
    setIsConsumable(null);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("utilize_stock").close();
      clearFields();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement
        id={"utilize_stock"}
        title={"Utilize Stock"}
        onClose={clearFields}
      >
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Quantity"}
                placeholder={"Enter Quantity"}
                type={"number"}
                value={utilizationQuantity}
                onChange={(e) => setUtilizationQuantity(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Utilization Date"}
                placeholder={"Utilization Date"}
                type={"date"}
                value={utilizationDate}
                onChange={(e) => setUtilizationDate(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Remark"}
                placeholder={"Enter Remarks"}
                type={"text"}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                isTextArea
              />
            </div>
          </div>
          <div className="flex gap-x-4 mb-4">
            <CustomCheckBox
              text={"Consumable"}
              onChange={(e) => setIsConsumable(e.target.checked)}
              checked={isConsumable}
            />
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              icon={BiXCircle}
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 h-[40px] w-auto"}
              onClick={() => {
                document.getElementById("utilize_stock").close();
                clearFields();
              }}
            />
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Add Utilization"}
              onClick={handleUtilization}
              isLoading={isLoading}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default UtilizationModal;
