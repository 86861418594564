const config = {
  development: {
    BASE_URL: "https://dev.v2.emmsapis.com/api/v1",
    CENTRIFUGO_URL:
      "wss://centrifugo.dev.v2.napims360.com/connection/websocket",
    REMITA_SCRIPT_URL:
      "https://login.remita.net/payment/v1/remita-pay-inline.bundle.js",
    REACT_APP_REMITA_PUBLIC_KEY:
      "TkFQSU1TfDg4MzkzODExNzF8NjA1NzRkODViZDIwN2YyMWUxZmY2MzY0NmZiMzVmNWZlZTRjZTA5NGZjMjllZmYzN2Y5NGJmZDg3MGIzZjBmNGE1ZGJkNmNjYThlZTI5ZDViMjUwYjY4YzAzMWVkMTBkZGRkNWY2OTA2NTU2MTA4ODEzOWZmY2JjMjA4MmUyMGY=",
  },
  staging: {
    BASE_URL: "https://stage.v2.emmsapis.com/api/v1",
    CENTRIFUGO_URL:
      "wss://centrifugo.stage.v2.napims360.com/connection/websocket",
    REMITA_SCRIPT_URL:
      "https://remitademo.net/payment/v1/remita-pay-inline.bundle.js",
    REACT_APP_REMITA_PUBLIC_KEY:
      "TkFQSU1TfDg4MzkzODExNzF8NjA1NzRkODViZDIwN2YyMWUxZmY2MzY0NmZiMzVmNWZlZTRjZTA5NGZjMjllZmYzN2Y5NGJmZDg3MGIzZjBmNGE1ZGJkNmNjYThlZTI5ZDViMjUwYjY4YzAzMWVkMTBkZGRkNWY2OTA2NTU2MTA4ODEzOWZmY2JjMjA4MmUyMGY=",
  },
  production: {
    BASE_URL: "https://api.v2.emmsportal.com/api/v1",
    CENTRIFUGO_URL:
      "wss://centrifugo.prod.v2.napims360.com/connection/websocket",
    REMITA_SCRIPT_URL:
      "https://login.remita.net/payment/v1/remita-pay-inline.bundle.js",
    REACT_APP_REMITA_PUBLIC_KEY:
      "TkFQSU1TfDg4MzkzODExNzF8NjA1NzRkODViZDIwN2YyMWUxZmY2MzY0NmZiMzVmNWZlZTRjZTA5NGZjMjllZmYzN2Y5NGJmZDg3MGIzZjBmNGE1ZGJkNmNjYThlZTI5ZDViMjUwYjY4YzAzMWVkMTBkZGRkNWY2OTA2NTU2MTA4ODEzOWZmY2JjMjA4MmUyMGY=",
  },
  local: {
    // BASE_URL: "https://emms-v2-0cd54c2c1466.herokuapp.com/api/v1",
    BASE_URL: "https://dev.v2.emmsapis.com/api/v1",
    CENTRIFUGO_URL:
      "wss://centrifugo.dev.v2.napims360.com/connection/websocket",
    REMITA_SCRIPT_URL:
      "https://login.remita.net/payment/v1/remita-pay-inline.bundle.js",
    REACT_APP_REMITA_PUBLIC_KEY:
      "TkFQSU1TfDg4MzkzODExNzF8NjA1NzRkODViZDIwN2YyMWUxZmY2MzY0NmZiMzVmNWZlZTRjZTA5NGZjMjllZmYzN2Y5NGJmZDg3MGIzZjBmNGE1ZGJkNmNjYThlZTI5ZDViMjUwYjY4YzAzMWVkMTBkZGRkNWY2OTA2NTU2MTA4ODEzOWZmY2JjMjA4MmUyMGY=",
  },
};

export default config[process.env.REACT_APP_ENV || "local"];
